import 'flatpickr/dist/themes/material_green.css';

import React, { useContext, useEffect, useState } from 'react';

import { Auth, Storage } from 'aws-amplify';
import { navigate } from 'gatsby';
import moment from 'moment';
import { AuthContext } from '@/api/auth';
import { useQuery } from '@apollo/client';
import {
  changeMembership,
  getMember,
  getMemberContractByMemberId,
  getVoucherByVoucherCode,
  healthQuestionsByBrandId,
  listMembershipId,
  updateMemberInfo,
  chargeSinglePaymentViaVivaPay,
} from '@/api/mutation';
import ArrowButton from '@/common/buttons/ArrowButton';
import 'flatpickr/dist/themes/material_green.css';
import CheckboxActive from '@/common/buttons/CheckboxActive';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import InputAnimated from '@/common/inputs/InputAnimated';
import InputBase from '@/common/inputs/InputBase';
import InputNumber from '@/common/inputs/InputNumber';
import ModalBase from '@/common/modals/ModalBase';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import ModalMakePayment from '@/common/payment/modalMakePayment';
import MyReactQuill from '@/components/MyReactQuill';
import { breakpoints } from '@/constants';
import useFetch from '@/hooks/useFetch';
import useWindowSize from '@/hooks/useWindowSize';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import MembershipsList from '../../../../components/joining-process/Memberships';
import congratsImg from '@/assets/images/congrats.png';
import arrowRight from '../../../../assets/images/arrowRight.svg';

import appstore from '@/assets/images/appstore.png';
import googlePlay from '@/assets/images/chplay.png';
import Switch from '@/common/buttons/Switch';
import { Modal } from 'react-responsive-modal';
import ThreeDot from '@/common/loading/ThreeDot';
import index from '@/routes/ConsentFormPage';
import { validateChangeVoucher } from '@/utils/ValidateChangeVoucher';
import { calculateDateFromSpecificDate } from '@/utils/CalculateDateFromSpecificDate';
import { useMemberShipContext } from '@/context/MembershipContext';

interface IDataTermsAndConditionPage {
  title: string;
  content: string;
}
interface UIQuestion extends IQuestion {
  userValue: string;
}

function ChangeClub({ willRefetch, membership, moveTab }) {
  const { width } = useWindowSize();
  const setDataAccount = (e) => {
    if (e.target.name === 'cardCvv') {
      paymentInfoCard.cardType == 'American express'
        ? (e.target.maxLength = '4')
        : (e.target.maxLength = '3');
    }
    if (e.target.name === 'cardExpiryDate') {
      if (e.target.value.length === 2) {
        e.target.maxLength = '5';
        e.target.value = e.target.value + '/';
      } else if (
        e.target.value.length === 3 &&
        e.target.value.charAt(2) === '/'
      )
        e.target.value = e.target.value.replace('/', '');
    }
    if (e.target.name === 'cardNumber') {
      let cardTypeDetect = '';
      if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(e.target.value.trim())) {
        cardTypeDetect = 'Visa';
      } else if (
        /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/.test(
          e.target.value.trim()
        )
      ) {
        cardTypeDetect = 'Mastercard';
      } else if (/^3[47][0-9]{13}$/.test(e.target.value.trim())) {
        cardTypeDetect = 'American express';
      }
      setPaymentInfoCard({
        ...paymentInfoCard,
        [e.target.name]: e.target.value,
        cardType: cardTypeDetect,
      });
    } else
      setPaymentInfoCard({
        ...paymentInfoCard,
        [e.target.name]: e.target.value,
      });
  };
  const [paymentInfoCard, setPaymentInfoCard] = useState({
    cardNumber: '',
    cardExpiryDate: '',
    cardType: '',
    cardCvv: '',
    cardHolderName: '',
  });
  const [TAndC, setTAndC] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);

  const { data: dataTermAndCondition } = useFetch({
    params: 'term-and-condition',
    populate: '',
  });
  const { data: dataPrivacyPolicy } = useFetch({
    params: 'privacy-policy',
    populate: '',
  });

  const termAndCondition: IDataTermsAndConditionPage =
    dataTermAndCondition?.data?.attributes;
  const privacyPolicyData: IDataTermsAndConditionPage =
    dataPrivacyPolicy?.data?.attributes;

  const userInfo = useContext(AuthContext).userInfo;
  const setUserInfo = useContext(AuthContext).setUserInfo;
  const { userSelectedMembership, membershipInfo } = useMemberShipContext();
  const [step, setStep] = useState(1);
  const [healthData, setHealthData] = useState();

  const [info, setInfo] = useState({
    memberDetails: {
      dob: userInfo?.dob,
      homeLocationId: userInfo?.homeLocationId,
      membershipId: membership?.membershipId,
      newLocationId: '',
      state: '',
      suburb: '',
      openDate: '',
    },
  });
  const [isStart, setIsStart] = useState(false);
  const [getVoucherByCode] = useLazyQuery(getVoucherByVoucherCode);
  const [updatedInfo, setUpdatedInfo] = useState({ ...userInfo });
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherDetail, setVoucherDetail] = useState({});
  const [voucherPercentPrice, setVoucherPercentPrice] = useState<number>(0);

  const [prefixEmergencyNumber, setPrefixEmergencyNumber] = React.useState<
    '+61' | '+64'
  >('+61');

  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == 'emergencyContactNumber') {
      value = prefixEmergencyNumber + value;
      if (value.charAt(3) == '0') value = value.slice(0, 3) + value.slice(4);
    }
    setUpdatedInfo({ ...updatedInfo, [name]: value });
  };
  const [listMemberShipApi] = useLazyQuery(listMembershipId);
  const [modal, setModal] = useState(false);
  const [membershipDetail, setMembershipDetail] = useState({});
  const [newMembershipLocationId, setNewMembershipLocationId] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const [changeMembershipApi, { loading: changeMembershipLoading }] =
    useMutation(changeMembership);
  const [addMembership, { loading: addMembershipLoading }] = useMutation(
    chargeSinglePaymentViaVivaPay
  );
  const [membershipArr, setMembershipArr] = useState<any>([]);
  const [isPIF, setIsPIF] = useState<boolean>();
  const [newMembershipType, setNewMembershipType] = useState('');
  const [membershipType, setMembershipType] = React.useState<string>('');
  const [openModalPayment, setModalPayment] = useState(false);
  const [addMembershipPaymentModal, setAddMembershipPaymentModal] =
    useState(false);
  const [amountChangeMembership, setAmountChangeMembership] = useState('');
  const [memberId, setMemberId] = useState('');
  const [changeMembershipData, setChangeMembershipData] = useState({
    changeMembershipType: '',
    newMembershipId: '',
    locationId: '',
    changeMembershipFee: '',
    cancellationFee: '',
  });
  const [currentVoucherDetail, setCurrentVoucherDetail] = useState({});
  const [nextDirect, setNextDirect] = useState<string | Date>('');
  const [currentMembership, setCurrentMembership] = useState();
  const [newLocationId, setNewLocationId] = useState('');
  const [getHealthQuestion] = useLazyQuery(healthQuestionsByBrandId);
  const [healthQuestions, setHealthQuestions] = useState<UIQuestion[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPaymentDelay, setIsPaymentDelay] = useState(false);
  const [isVoucherDelay, setIsVoucherDelay] = useState(false);
  const [locationFee, setLocationFee] = useState<null | Number>();

  const getHealthQuestions = () => {
    getHealthQuestion().then((res: any) => {
      const questions: UIQuestion[] =
        res.data?.getHealthQuestionnairesByBrandId.items.map((i: IQuestion) => {
          return { ...i, userValue: '' };
        });
      let sortedQuestion = [...questions];
      sortedQuestion.push(
        sortedQuestion.splice(
          sortedQuestion.findIndex((i: UIQuestion) =>
            i.question
              .toLowerCase()
              .includes(
                'I am under the supervision of a medical practitioner or I will seek guidance from an appropriate health or medical practitioner prior to undertaking exercise.'.toLowerCase()
              )
          ),
          1
        )[0]
      );
      setHealthQuestions(sortedQuestion);
    });
  };
  useEffect(() => {
    setHealthData(
      [...healthQuestions].reduce(
        (obj, item) => ({ ...obj, [item.id]: item.userValue }),
        {}
      )
    );
  }, [healthQuestions]);
  React.useEffect(() => {
    listMemberShipApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        const ageInfo = moment(new Date()).diff(
          moment(info?.memberDetails?.dob),
          'years'
        );
        sortData(
          membership.getMembershipByBrandId.items.filter(
            (i) => i.id === info?.memberDetails.membershipId
          )[0],
          ageInfo,
          false
        );
        setCurrentMembership(
          membership.getMembershipByBrandId.items.filter(
            (i) => i.id === info?.memberDetails.membershipId
          )[0]
        );
      }
    });
    handleFetchData();
    getHealthQuestions();
  }, []);

  useEffect(() => {
    if (
      Object.keys(voucherDetail || {}).length &&
      voucherDetail.type === 'PERCENT'
    ) {
      const membershipLocation =
        voucherDetail?.membershipDetail?.membershipLocations.items.filter(
          (item) =>
            info?.memberDetails?.newLocationId
              ? item.locationId === info?.memberDetails?.newLocationId
              : item.locationId === info?.memberDetails?.homeLocationId
        );
      setVoucherPercentPrice(
        membershipLocation[0]?.costPrice -
          membershipLocation[0]?.costPrice * (voucherDetail?.cost * 0.01)
      );
    }
    if (Object.keys(voucherDetail || {}).length > 0) {
      if (
        voucherDetail?.paymentDelay?.amount !== 0 &&
        voucherDetail?.paymentDelay !== null &&
        !Object.keys(membership || {}).length &&
        Object.keys(voucherDetail || {}).length &&
        voucherCode
      ) {
        setIsVoucherDelay(true);
        setNextDirect(
          moment(
            calculateDateFromSpecificDate({
              type: voucherDetail?.paymentDelay?.type,
              amount: voucherDetail?.paymentDelay?.amount,
              date:
                info.memberDetails?.openDate &&
                moment(info.memberDetails?.openDate).isAfter(moment())
                  ? info.memberDetails?.openDate
                  : '',
            })
          ).format('DD/MM/YYYY')
        );
      } else {
        setIsVoucherDelay(false);
        setNextDirect(
          moment(
            calculateDateFromSpecificDate({
              type: info.membershipDetails?.paymentFrequency?.type,
              amount: info.membershipDetails?.paymentFrequency?.amount,
              date:
                info.memberDetails?.openDate &&
                moment(info.memberDetails?.openDate).isAfter(moment())
                  ? info.memberDetails?.openDate
                  : '',
            })
          ).format('DD/MM/YYYY')
        );
      }
    }
  }, [voucherDetail]);

  useEffect(() => {
    setVoucherCode('');
    setVoucherDetail({});
  }, [info?.memberDetails?.NewMembershipId]);

  const sortData = (items, age, isNewMemberships) => {
    const upgrade = ['CL ', 'HR ', 'GU ', 'LO ', ' + ', 'RB '];
    if (items) {
      const lowerClassName = items.membershipName?.toLowerCase();
      const lowerDesc = items.description?.toLowerCase();
      const result = upgrade.some((substr) =>
        lowerDesc?.includes(substr.toLowerCase())
      );

      if (!result && items.isActive) {
        if (
          // true
          (age === 16 && lowerClassName.includes('flexi')) ||
          (age > 16 &&
            (lowerClassName.includes('flexi') ||
              lowerClassName.includes('power') ||
              (!changeMembership && lowerClassName.includes('guest')))) ||
          lowerDesc?.includes('pif') ||
          items.recurring ||
          (age <= 13 && lowerClassName.includes('junior')) ||
          (age > 13 && age < 16 && lowerClassName.includes('youth'))
        ) {
          if (lowerDesc.includes('pif')) {
            setIsPIF(true);
            if (isNewMemberships) {
              setNewMembershipType('pif');
            }
          } else if (
            items.paymentFrequency.type === 'WEEKS' &&
            !lowerDesc.includes('student')
          ) {
            setIsPIF(false);
            if (isNewMemberships) {
              setNewMembershipType('weekly');
            }
          }
        }
      }

      // setOptions(['weekly',"annual","student"]);S
    }
  };
  const compareDates = (a, b) => {
    const dateA = new Date(a?.createdAt);
    const dateB = new Date(b?.createdAt);

    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  };
  const { data: memberDetail } = useQuery(getMember, {
    fetchPolicy: 'no-cache',
    variables: {
      memberId: memberId,
    },
    onCompleted: (data) => {
      if (data?.getMember?.voucherMembers?.items.length) {
        let copyData = data?.getMember?.voucherMembers?.items;
        copyData.sort(compareDates);
        const filterExpiredVoucher = copyData.filter((item) => {
          const dateExpired = moment(
            calculateDateFromSpecificDate({
              type: item?.voucherDetail?.membershipDetail?.contractLength?.type,
              amount:
                item?.voucherDetail?.membershipDetail?.contractLength?.amount,
              date: item?.createdAt,
            })
          );
          const complimentaryExpired = moment(item?.createdAt).add(
            item?.voucherDetail?.duration,
            'days'
          );
          if (
            item?.voucherDetail?.type !== 'COMPLIMENTARY' &&
            item?.voucherDetail?.type !== 'VISIT_PASS'
          ) {
            return (
              !item.status ||
              item.status !== 'CANCELLED' ||
              (item.voucherDetail.membershipDetail.contractLength?.type &&
                (moment().isBefore(dateExpired) ||
                  moment().isSame(dateExpired)))
            );
          } else {
            return (
              (moment().isBefore(complimentaryExpired) ||
                moment().isSame(complimentaryExpired)) &&
              (!item?.status || item?.status !== 'CANCELLED')
            );
          }
        });
        const currentVoucher = filterExpiredVoucher.filter((item) =>
          membershipInfo.some(
            (i) => i.membershipId === item?.voucherDetail?.membershipDetail.id
          )
        );
        setCurrentVoucherDetail(currentVoucher[0]?.voucherDetail);
      }
    },
  });
  const handleSelect = (v) => {
    // setModal(true)
    setInfo({
      memberDetails: {
        ...info.memberDetails,
        NewMembershipId: v.memberDetails.NewMembershipId,
        newLocationId: v.memberDetails.newLocationId,
        state: v.memberDetails.state,
        suburb: v.memberDetails.suburb,
        openDate: v.memberDetails.openDate,
        membershipLocationId: v.memberDetails.membershipLocationId,
      },
      membershipDetails: { ...v.membershipDetails },
    });
    if (
      v.membershipDetails?.paymentDelay?.amount !== 0 &&
      v.membershipDetails?.paymentDelay !== null
    ) {
      setIsPaymentDelay(true);
      setNextDirect(
        moment(
          calculateDateFromSpecificDate({
            type: v.membershipDetails?.paymentDelay?.type,
            amount: v.membershipDetails?.paymentDelay?.amount,
            date:
              v.memberDetails?.openDate &&
              moment(v.memberDetails?.openDate).isAfter(moment())
                ? v.memberDetails?.openDate
                : '',
          })
        ).format('DD/MM/YYYY')
      );
    } else {
      if (Object.keys(membership || {})?.length) {
        const nearestBilling = nearestDate(
          membership?.billings?.items?.filter((i) => !i.isProcessed)
        );
        setNextDirect(moment(nearestBilling?.debitDate).format('DD/MM/YYYY'));
      } else {
        setNextDirect(
          moment(
            calculateDateFromSpecificDate({
              type: v.membershipDetails?.paymentFrequency?.type,
              amount: v.membershipDetails?.paymentFrequency?.amount,
              date:
                v.memberDetails?.openDate &&
                moment(v.memberDetails?.openDate).isAfter(moment())
                  ? v.memberDetails?.openDate
                  : '',
            })
          ).format('DD/MM/YYYY')
        );
      }

      setIsPaymentDelay(false);
    }

    setMembershipDetail({
      ...v.membershipDetails,
      membershipNew: v.memberDetails,
    });

    let membershipIsActive = [];

    if (info.memberDetails.newLocationId) {
      membershipIsActive =
        v.membershipDetails?.membershipLocations?.items.filter(
          (i) =>
            i.isActive && i?.locationId === info.memberDetails.newLocationId
        );
    } else {
      membershipIsActive =
        v.membershipDetails?.membershipLocations?.items.filter(
          (i) =>
            i.isActive && i?.locationId === info?.memberDetails?.homeLocationId
        );
    }
    if (membershipIsActive?.length > 0) {
      const activeMembership = membershipIsActive[0];
      if (
        activeMembership.activationFee !== null ||
        activeMembership.fobFee !== null ||
        activeMembership.joiningFee !== null
      ) {
        setLocationFee(
          (activeMembership.activationFee || 0) +
            (activeMembership.fobFee || 0) +
            (activeMembership.joiningFee || 0)
        );
      } else {
        setLocationFee(null);
      }
    } else {
      setLocationFee(null);
    }
    setNewMembershipLocationId(membershipIsActive?.[0]?.id);
    const ageInfo = moment(new Date()).diff(
      moment(info?.memberDetails?.dob),
      'years'
    );
    sortData(v.membershipDetails, ageInfo, true);
  };
  const handleCallApiChangeMembership = () => {
    setModal(true);
  };

  function createMarkup(data) {
    return { __html: data };
  }

  const [isShowGender, setShowGender] = useState(false);
  const [selectedGender, setSelectedGender] = useState(userInfo?.gender);

  const setRefresh = useContext(AuthContext).setRefreshData;
  const [updateMemberApi] = useMutation(updateMemberInfo);

  const handleUpdateInfo = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const payload = {};
      for (let i = 0; i < Object.keys(userInfo).length; i++) {
        if (!(Object.values(updatedInfo)[i] === Object.values(userInfo)[i])) {
          payload[Object.keys(userInfo)[i]] = Object.values(updatedInfo)[i];
        }
      }

      payload.memberId = user.attributes['custom:memberId'];

      delete payload.imageUrl;

      if (
        !updatedInfo.emergencyContactName ||
        !updatedInfo.emergencyContactNumber ||
        !updatedInfo.emergencyContactRelation
      ) {
        toastError('Please provide emergency contact');
        return;
      }
      const data = await updateMemberApi({
        variables: {
          input: {
            ...payload,
            ...(info.memberDetails.state && {
              state: info.memberDetails.state,
            }),
            ...(info.memberDetails.newLocationId && {
              homeLocationId: info.memberDetails.newLocationId,
            }),
            ...(info.memberDetails.suburb && {
              suburb: info.memberDetails.suburb,
            }),
          },
        },
      });
    } catch (e) {
      toastError(e.message);
    } finally {
      setRefresh(true);
    }
  };
  const client = useApolloClient();
  client.refetchQueries({
    include: ['getMember'], // Consider using "active" instead!
  });
  const [accept, setAccept] = useState(false);
  const [confirmRequirement, setConfirmRequirement] = useState(false);
  const [getMembership] = useLazyQuery(getMemberContractByMemberId);

  const nearestDate = (dateArray, targetDate) => {
    // Convert targetDate to Date object if it's not already
    if (!targetDate) {
      targetDate = new Date();
    } else if (!(targetDate instanceof Date)) {
      targetDate = new Date(targetDate);
    }
    const futureDates = dateArray?.filter(
      (date) => new Date(date.debitDate) > targetDate
    );

    // If there are no future dates, return null
    if (futureDates?.length === 0) {
      return null;
    }

    // Calculate the absolute difference between targetDate and each future date
    const diffs = futureDates?.map((date) =>
      Math.abs(new Date(date.debitDate) - targetDate)
    );

    // Find the index of the minimum absolute difference
    const minDiffIndex = diffs?.indexOf(Math.min(...diffs));

    // Return the future date at that index
    return futureDates?.[minDiffIndex];
  };
  const handleFetchData = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setMemberId(user.attributes['custom:memberId']);
    const userContracts = await getMembership({
      variables: {
        memberId: user.attributes['custom:memberId'],
      },
    });
    const dataFilter = nearestDate(
      userContracts.data.getMemberContractByMemberId.items[0]?.billings?.items?.filter(
        (i) => !i.isProcessed
      )
    );
    if (
      userContracts.data.getMemberContractByMemberId.items[0]?.billings?.items
        .length > 0
    ) {
      setNextDirect(
        dataFilter ? moment(dataFilter?.debitDate).format('DD/MM/YYYY') : ''
      );
    }
  };

  const handleOpenMakePaymentModal = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setLoadingModal(true);
      const userContracts = await getMembership({
        variables: {
          memberId: user.attributes['custom:memberId'],
        },
      });

      let data;
      if (Object.keys(membership || {}).length)
        data = await changeMembershipApi({
          variables: {
            memberId: user.attributes['custom:memberId'],
            membershipLocationId: newMembershipLocationId,
            newMembershipId: membershipDetail.membershipNew.membershipId,
            oldMemberContractId:
              userContracts.data.getMemberContractByMemberId.items.filter(
                (i) => {
                  if (i.expiryDateTime != null) {
                    if (
                      new Date(i.expiryDateTime).valueOf() <
                      new Date().valueOf()
                    ) {
                      return false;
                    }
                  }
                  if (i.endDateTime != null) {
                    if (
                      new Date(i.endDateTime).valueOf() < new Date().valueOf()
                    ) {
                      return false;
                    }
                  }
                  if (i.membershipId !== userSelectedMembership?.membershipId) {
                    return false;
                  }
                  return true;
                }
              )[0]?.id ?? '',
            getInformation: true,
            ...(Object.keys(voucherDetail || {}).length && {
              voucherCode: voucherDetail?.voucherCode,
            }),
          },
        });

      willRefetch(true);
      const currentPrice =
        currentVoucherDetail?.membershipDetail?.id === membership?.membershipId
          ? currentVoucherDetail?.type === 'PERCENT'
            ? (
                currentVoucherDetail?.membershipDetail?.costPrice -
                currentVoucherDetail?.membershipDetail?.costPrice *
                  (currentVoucherDetail?.cost * 0.01)
              ).toFixed(2)
            : currentVoucherDetail?.cost
          : userSelectedMembership?.costPrice;

      const newPrice = Object.keys(voucherDetail).length
        ? voucherDetail?.type === 'PERCENT'
          ? (
              voucherDetail?.membershipDetail?.costPrice -
              voucherDetail?.membershipDetail?.costPrice *
                (voucherDetail?.cost * 0.01)
            ).toFixed(2)
          : voucherDetail?.cost
        : membershipDetail?.costPrice;
      const downGrade = currentPrice >= newPrice;
      const upGrade = currentPrice < newPrice;

      if (info.memberDetails.newLocationId) {
        setNewLocationId(
          membershipDetail?.membershipLocations.items.filter(
            (item) => item?.locationId === info.memberDetails.newLocationId
          )[0]?.locationId
        );
      } else {
        setNewLocationId(
          membershipDetail?.membershipLocations.items.filter(
            (item) => item?.locationId === info?.memberDetails?.homeLocationId
          )[0]?.locationId
        );
      }
      if (!Object.keys(membership || {}).length) {
      } else if (downGrade) {
        setAmountChangeMembership(
          Number(data.data.changeMembership.downgradeFee) +
            data.data.changeMembership?.cancellationFee
        );
        setChangeMembershipData({
          ...changeMembershipData,
          changeMembershipType: 'downGrade',
          newMemberShipId: membershipDetail,
          locationId:
            userContracts.data.getMemberContractByMemberId.items[0].locationId,
          changeMembershipFee: data.data.changeMembership.downgradeFee,
          cancellationFee: data.data.changeMembership?.cancellationFee,
        });
      } else if (upGrade) {
        setAmountChangeMembership(
          Number(data.data.changeMembership.upfrontPaymentAmount) +
            data.data.changeMembership?.cancellationFee
        );
        setChangeMembershipData({
          ...changeMembershipData,
          changeMembershipType: 'upGrade',
          newMemberShipId: membershipDetail,
          locationId:
            userContracts.data.getMemberContractByMemberId.items[0].locationId,
          changeMembershipFee: data.data.changeMembership.upfrontPaymentAmount,
          cancellationFee: data.data.changeMembership?.cancellationFee,
        });
      } else if (isPIF && newMembershipType === 'pif') {
        setAmountChangeMembership(
          Number(data.data.changeMembership.upfrontPaymentAmount) +
            data.data.changeMembership?.cancellationFee
        );
        if (downGrade) {
          setChangeMembershipData({
            ...changeMembershipData,
            changeMembershipType: 'downGrade',
            newMemberShipId: membershipDetail,
            locationId:
              userContracts.data.getMemberContractByMemberId.items[0]
                .locationId,
            changeMembershipFee: data.data.changeMembership.downgradeFee,
            cancellationFee: data.data.changeMembership?.cancellationFee,
          });
        } else if (upGrade) {
          setChangeMembershipData({
            ...changeMembershipData,
            changeMembershipType: 'upGrade',
            newMemberShipId: membershipDetail,
            locationId:
              userContracts.data.getMemberContractByMemberId.items[0]
                .locationId,
            changeMembershipFee:
              data.data.changeMembership.upfrontPaymentAmount,
            cancellationFee: data.data.changeMembership?.cancellationFee,
          });
        }
      }

      setModalPayment(true);
    } catch (e) {
      toastError(e.message);
      setStep(1);
    } finally {
      setLoadingModal(false);
    }
  };
  const applyVoucher = async () => {
    const user = await Auth.currentAuthenticatedUser();
    try {
      if (voucherCode) {
        getVoucherByCode({
          fetchPolicy: 'no-cache',
          variables: {
            voucherCode: voucherCode,
          },
          onCompleted: (data) => {
            validateChangeVoucher(
              data,
              user.attributes['custom:memberId'],
              memberDetail,
              info,
              setVoucherDetail,
              toastError,
              toastSuccess,
              voucherCode,
              [membership]
            );
          },
        });
      } else {
        setVoucherDetail({});
      }
    } catch (err) {
      toastError(`Error: ${err.message}`);
    }
  };

  const confirmNewMembership = () => {
    if (voucherCode && Object.keys(voucherDetail || {}).length) {
      setStep(step + 1);
    } else if (voucherCode && !Object.keys(voucherDetail || {}).length) {
      toastError('This voucher does not valid!');
    } else {
      setStep(step + 1);
    }
  };
  const handleRenewMembershipDelay = () => {
    const paymentInformation = userInfo?.paymentDetails?.items.find(
      (item) => item.primary
    );
    addMembership({
      variables: {
        memberId: userInfo?.memberId,
        brandId: userInfo.brandId,
        addMembershipArguments: {
          membershipStartDate: new Date().toISOString().split('T')[0],
          newLocationId: userInfo.homeLocationId,
          newMembershipId: membershipDetail.membershipNew.NewMembershipId,
          voucherCode: voucherCode,
          healthQuestionnaire: JSON.stringify(healthData),
        },
        amount: 0,
        operation: 'AMS',
        paymentInformationId: paymentInformation?.id,
        futurePaymentOption: 'CARD_ON_FILE_IS_DEFAULT',
      },
      onCompleted: (data) => {
        handleUpdateInfo();
        setRefresh(true);
        setStep(6);
      },
      onError: (error) => {
        toastError('Error: Cannot renew your membership');
      },
    });
  };

  const handleOnOffInitial = (i: UIQuestion) => {
    if (i.userValue !== '') {
      return i.userValue !== '' ? (i.userValue == 'no' ? 'off' : 'on') : 'off';
    }
    return null;
  };
  const healthCheck = () => {
    if (terms) {
      if (
        healthQuestions.every((i: UIQuestion) =>
          i.answerType === 'NUMBER'
            ? i.mandatory
              ? i.expectedAnswer !== ''
                ? i.userValue === i.expectedAnswer
                : i.userValue !== ''
              : true
            : i.answerType === 'STRING'
            ? i.mandatory
              ? i.userValue != ''
              : true
            : false
        )
      ) {
        setIsSubmitted(true);
        return true;
      } else {
        setIsSubmitted(true);
        toastError('Please ensure to answer all the required questions.');
        return false;
      }
    } else {
      return true;
    }
  };

  const handleChangeMembershipNoCharge = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userContracts = await getMembership({
      variables: {
        memberId: user.attributes['custom:memberId'],
      },
    });

    await changeMembershipApi({
      variables: {
        memberId: user.attributes['custom:memberId'],
        membershipLocationId: newMembershipLocationId,
        newMembershipId: membershipDetail.membershipNew.membershipId,
        oldMemberContractId:
          userContracts.data.getMemberContractByMemberId.items.filter((i) => {
            if (i.expiryDateTime != null) {
              if (new Date(i.expiryDateTime).valueOf() < new Date().valueOf()) {
                return false;
              }
            }
            if (i.endDateTime != null) {
              if (new Date(i.endDateTime).valueOf() < new Date().valueOf()) {
                return false;
              }
            }
            if (i.membershipId !== userSelectedMembership?.membershipId) {
              return false;
            }
            return true;
          })[0]?.id ?? '',
        getInformation: true,
        ...(Object.keys(voucherDetail || {}).length && {
          voucherCode: voucherDetail?.voucherCode,
        }),
      },
      onCompleted: (data) => {
        const currentPrice =
          currentVoucherDetail?.membershipDetail?.id ===
          info.memberDetails.membershipId
            ? currentVoucherDetail?.type === 'PERCENT'
              ? (
                  currentVoucherDetail?.membershipDetail?.costPrice -
                  currentVoucherDetail?.membershipDetail?.costPrice *
                    (currentVoucherDetail?.cost * 0.01)
                ).toFixed(2)
              : currentVoucherDetail?.cost
            : userSelectedMembership?.costPrice;
        const newPrice = Object.keys(voucherDetail).length
          ? voucherDetail?.type === 'PERCENT'
            ? (
                voucherDetail?.membershipDetail?.costPrice -
                voucherDetail?.membershipDetail?.costPrice *
                  (voucherDetail?.cost * 0.01)
              ).toFixed(2)
            : voucherDetail?.cost
          : membershipDetail?.costPrice;
        const downGrade = currentPrice >= newPrice;

        if (downGrade) {
          if (
            data?.changeMembership?.downgradeFee !== '0' ||
            data?.changeMembership?.cancellationFee !== 0
          ) {
            handleOpenMakePaymentModal();
          } else {
            changeMembershipApi({
              variables: {
                memberId: user.attributes['custom:memberId'],
                membershipLocationId: newMembershipLocationId,
                newMembershipId: membershipDetail.membershipNew.membershipId,
                oldMemberContractId:
                  userContracts.data.getMemberContractByMemberId.items.filter(
                    (i) => {
                      if (i.expiryDateTime != null) {
                        if (
                          new Date(i.expiryDateTime).valueOf() <
                          new Date().valueOf()
                        ) {
                          return false;
                        }
                      }
                      if (i.endDateTime != null) {
                        if (
                          new Date(i.endDateTime).valueOf() <
                          new Date().valueOf()
                        ) {
                          return false;
                        }
                      }
                      if (
                        i.membershipId !== userSelectedMembership?.membershipId
                      ) {
                        return false;
                      }
                      return true;
                    }
                  )[0]?.id ?? '',
                getInformation: false,
                voucherCode: voucherDetail?.voucherCode,
              },
              onCompleted: (data) => {
                handleUpdateInfo();
                setRefresh(true);
                setStep(6);
              },
              onError: (e) => {
                toastError(e.message);
              },
            });
          }
        }
      },
      onError: (e) => {
        toastError(e.message);
      },
    });
  };

  const ConfirmMembershipInfo = () => {
    return (
      <div>
        <p className="sm:hidden uppercase font-bold leading-[90%] text-[30px] text-white h-[46px]">
          Confirm your new membership
        </p>
        <div className="border-b border-[rgba(255,255,255,0.4)]">
          <p className="sm:hidden text-white font-medium text-sm leading-[140%] tracking-[1.4px] uppercase border-b-[#d3d3d3] border-b-2 pb-[20px]">
            MEMBERSHIP PLAN
          </p>
          <div className="flex justify-between pr-[13px] items-center">
            <p className="sm:hidden text-white font-normal leading-[160%] text-xs mt-6">
              Membership Plan
            </p>
            <p className="sm:hidden text-[rgba(255,255,255,0.57)] font-normal leading-[160%] text-xs mt-6 underline">
              Edit
            </p>
          </div>
          <div>
            <div className="flex justify-between pr-[13px] items-center mt-[16px] sm:pt-4 sm:pb-[6px] ">
              <p className="text-white text-[11px] font-medium sm:text-base sm:font-normal">
                {membershipDetail.membershipName}
              </p>
              <p className="text-white text-[11px] font-medium sm:text-base sm:font-normal">
                $
                {Object.keys(voucherDetail || {}).length && voucherCode
                  ? voucherDetail.type === 'PERCENT'
                    ? (
                        voucherPercentPrice /
                        voucherDetail.membershipDetail.paymentFrequency.amount
                      ).toFixed(2)
                    : (
                        voucherDetail.cost /
                        voucherDetail.membershipDetail.paymentFrequency.amount
                      ).toFixed(2)
                  : (
                      membershipDetail.costPrice /
                      membershipDetail.paymentFrequency.amount
                    ).toFixed(2)}{' '}
                {membershipDetail.paymentFrequency.type === 'WEEKS' &&
                  'per week'}
              </p>
            </div>
            <div className=" justify-between pr-[13px] items-center sm:pb-4 hidden sm:flex ">
              <p className="text-white text-[11px] font-medium sm:text-base sm:font-normal">
                Activation Fee
              </p>
              <p className="text-white text-[11px] font-medium sm:text-base sm:font-normal">
                $
                {Object.keys(voucherDetail || {}).length
                  ? voucherDetail.joiningFee +
                    voucherDetail.activationFee +
                    voucherDetail.passFee
                  : membershipDetail.joiningFee +
                    membershipDetail.joiningFee2 +
                    membershipDetail.joiningFee3}
              </p>
            </div>
          </div>
          <p
            className={`mt-[6px] sm:text-base text-start h-[34px] mb-[7px] sm:mb-[20px] leading-[132%]  ${
              newMembershipType == 'weekly'
                ? 'text-[rgba(255,255,255,0.6)] text-center text-xs font-normal'
                : 'text-white text-center text-base font-medium'
            }`}
          >
            {newMembershipType == 'weekly' ? (
              <>
                $
                {Object.keys(voucherDetail || {}).length && voucherCode
                  ? voucherDetail.type === 'PERCENT'
                    ? voucherPercentPrice.toFixed(2)
                    : voucherDetail?.cost?.toFixed(2)
                  : membershipDetail.costPrice?.toFixed(2)}{' '}
                fortnightly Direct Debit <br />{' '}
                {membershipDetail?.contractLength
                  ? membershipDetail?.contractLength?.amount +
                    ' ' +
                    membershipDetail?.contractLength?.type
                  : ''}
              </>
            ) : (
              'SINGLE PAYMENT'
            )}
            {}
          </p>
        </div>
        <div className="border-b border-[rgba(255,255,255,0.4)]">
          <div className="pt-[17px] pb-[20px] pr-[13px] flex justify-between items-center">
            <div className="flex flex-col gap-[11px]">
              <p className="text-white font-normal text-xs sm:text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
                Start date
              </p>
              <p className="text-white font-medium text-[11px] leading-[140%] uppercase sm:text-base sm:leading-[21.36px] sm:font-normal">
                {userInfo?.homeLocationDetails?.openDate &&
                moment(userInfo?.homeLocationDetails?.openDate).isAfter(
                  moment()
                )
                  ? moment(userInfo?.homeLocationDetails?.openDate).format(
                      'DD MMMM YYYY'
                    )
                  : info.memberDetails.openDate &&
                    moment(info.memberDetails.openDate).isAfter(moment())
                  ? moment(info.memberDetails.openDate).format('DD MMMM YYYY')
                  : moment().format('DD MMMM YYYY')}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between pr-[13px] items-center py-[16px] border-b border-[rgba(255,255,255,0.4)] sm:py-4 sm:flex-col sm:items-start sm:gap-y-1">
          <p className="text-white text-[12px] font-medium sm:text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
            {newMembershipType === 'weekly' ? 'Cost per fortnight' : 'Cost '}
          </p>
          <p className="text-white text-[11px] font-medium sm:text-base sm:leading-[21.36px] sm:font-normal">
            $
            {Object.keys(voucherDetail || {}).length && voucherCode
              ? voucherDetail.type === 'PERCENT'
                ? voucherPercentPrice.toFixed(2)
                : voucherDetail?.cost?.toFixed(2)
              : membershipDetail.costPrice?.toFixed(2)}
          </p>
        </div>
        <div className="flex justify-between pr-[13px] items-center py-[7px] border-b border-[rgba(255,255,255,0.4)] sm:py-4 sm:flex-col sm:items-start sm:gap-y-1">
          <p className="text-white text-[12px] font-medium sm:text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
            Promo code
          </p>
          <div className="flex">
            <input
              className="text-white mr-[7px] text-[11px] border-solid border-[1px] border-[rgba(255,255,255,0.40)] bg-transparent font-semibold rounded-md px-[8px] py-[9px] w-[142px]"
              maxLength={8}
              value={voucherCode}
              onChange={(e) => {
                setVoucherCode(e.target.value);
                if (!e.target.value) {
                  setVoucherDetail({});
                  setIsVoucherDelay(false);
                  setNextDirect(
                    moment(
                      calculateDateFromSpecificDate({
                        type: info.membershipDetails?.paymentFrequency?.type,
                        amount:
                          info.membershipDetails?.paymentFrequency?.amount,
                        date:
                          info.memberDetails?.openDate &&
                          moment(info.memberDetails?.openDate).isAfter(moment())
                            ? info.memberDetails?.openDate
                            : '',
                      })
                    ).format('DD/MM/YYYY')
                  );
                }
              }}
            />
            <PrimaryOutlinedButton
              onClick={() => {
                applyVoucher();
              }}
              className="uppercase  text-white font-medium border-white border rounded !py-0 !px-0 !h-[30px] !min-h-[30px] w-[50px] align-center gotham !text-[11.2px]"
            >
              apply
            </PrimaryOutlinedButton>
          </div>
        </div>
        {!isPIF && (
          <div className="flex justify-between pr-[13px] items-center py-[12px] border-b border-[rgba(255,255,255,0.4)] sm:py-4 sm:flex-col sm:items-start sm:gap-y-1">
            <p className="text-white text-[12px] font-medium sm:text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
              Next direct debit
            </p>
            <p className="text-white font-medium text-[12px] leading-[140%] uppercase sm:text-base sm:leading-[21.36px] sm:font-normal">
              {nextDirect
                ? nextDirect
                : moment()
                    .add(membershipDetail?.paymentFrequency?.amount * 7, 'days')
                    .format('DD/MM/YYYY')}
            </p>
          </div>
        )}
        <div className="sm:hidden flex items-center mt-[56px] justify-between">
          <div onClick={() => setStep(step - 1)}>
            <ArrowButton text="back" isRight={false} />
          </div>
          <div
            onClick={() => {
              confirmNewMembership();
            }}
          >
            <ArrowButton text="next" isRight={true} />
          </div>
        </div>
      </div>
    );
  };
  const [terms, setTerms] = useState<boolean>(false);
  const ConfirmYourDetails = () => {
    return (
      <div>
        <p className="sm:hidden uppercase font-bold leading-[90%] text-[30px] text-white h-[46px]">
          Confirm your details
        </p>
        <div className="">
          <p className="sm:hidden text-white font-medium text-sm leading-[140%] tracking-[1.4px] uppercase border-b-[#d3d3d3] border-b-2 pb-[20px]">
            contact details
          </p>
          <div className="sm:hidden grid grid-cols-5 gap-y-[14px] w-full pt-[14px] items-center">
            <div className="grid grid-cols-2 col-span-4">
              <InputAnimated
                readOnly
                label="First name"
                type="text"
                value={updatedInfo.givenName}
                name="surname"
              />
              <InputAnimated
                readOnly
                label="Last name"
                type="text"
                value={updatedInfo.surname}
                name="lastName"
              />
            </div>
            <div className="relative ">
              <div
                className={`flex items-center mt-[15px] pr-[12px] pb-[7px] bg-transparent border-b border-[rgba(255,255,255,0.4)] cursor-pointer w-full focus:ring-1`}
                // onClick={() => setShowGender(!isShowGender)}
              >
                <div className="bg-transparent h-[20px] outline-none w-full  truncate text-white">
                  <span
                    className={`form__label text-[#848484] gotham absolute  transition-all duration-300
                            ${
                              selectedGender
                                ? 'top-1 left-0 text-[11px]'
                                : 'text-sm top-[15px] left-0'
                            }
                            `}
                  >
                    Gender
                  </span>
                  <span className="text-sm gotham font-medium uppercase tracking-widest">
                    {selectedGender}
                  </span>
                </div>
                {/* <div
                  className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 ${
                    isShowGender
                      ? 'border-brand-clime rotate-[135deg]'
                      : 'border-gray'
                  }`}
                ></div> */}
              </div>
              <div
                className={`w-full bg-[#13181b] border-gray text-white border-2 absolute z-40 ${
                  isShowGender ? '' : 'hidden'
                }`}
              >
                {['MALE', 'FEMALE']?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className="cursor-pointer uppercase gotham font-medium tracking-widest text-xs px-4 py-2 hover:text-black hover:bg-primary"
                    // onClick={() => {
                    //   setShowGender(false);
                    //   setSelectedGender(item);
                    //   // setDropdownSelected(true);
                    // }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className="grid w-full col-span-5 grid-cols-2">
              <InputAnimated
                readOnly
                label="Email"
                type="text"
                value={updatedInfo.email}
                name="givenName"
              />
              <InputAnimated
                readOnly
                label="Phone number"
                type="text"
                value={updatedInfo.mobileNumber}
                name="givenName"
              />
            </div>
          </div>
          <div className="flex justify-between pr-[13px] items-center mt-[16px]"></div>
        </div>
        <div className="sm:hidden pb-[7px] border-b-2 border-b-white mt-[44px]">
          <h2 className="heading2 !text-[14px] text-white uppercase text-left font-medium">
            health check
          </h2>
        </div>
        <div className="mt-5 flex items-center justify-between sm:flex-col sm:items-start sm:gap-y-5 sm:mt-4">
          <h6 className="gotham text-sm text-gray sm:text-white sm:text-base sm:leading-[23px]">
            Do you have any injuries, disabilities or current health conditions?
            *
          </h6>
          <div className="flex items-center gap-4 sm:gap-5 sm:w-full">
            <div
              onClick={() => setTerms(true)}
              className={`${
                terms ? 'bg-primary border-none' : 'bg-transparent border-white'
              } sm:w-1/2 cursor-pointer flex items-center justify-center text-sm border rounded w-[70px] h-[37px]  text-white uppercase`}
            >
              yes
            </div>
            <div
              onClick={() => setTerms(false)}
              className={`${
                !terms
                  ? 'bg-primary border-none'
                  : 'bg-transparent border-white'
              } sm:w-1/2 cursor-pointer flex items-center justify-center border text-sm rounded bg-primary w-[70px] h-[37px] text-white uppercase`}
            >
              no
            </div>
          </div>
        </div>
        {/* MOBILE */}
        {!terms && (
          <div className="sm:block hidden">
            <div className="pb-[6px] border-b border-b-white border-opacity-40 mt-8">
              <h2 className="text-base text-white font-bold leading-[160%] uppercase">
                terms and conditions*
              </h2>
            </div>
            <div className="flex flex-col gap-y-5 mt-5 mb-7">
              <div className="flex items-center justify-between gap-x-4">
                <CheckboxActive onChecked={(e) => setAccept(e)} />
                <p className="text-base text-white leading-[133.5%]">
                  I confirm I have read and accept the terms and conditions.
                </p>
              </div>
              <div className="flex items-center justify-between gap-x-4">
                <CheckboxActive onChecked={(e) => setConfirmRequirement(e)} />
                <p className="text-base text-white leading-[133.5%]">
                  I confirm I meet the requirements from the pre-exercise
                  checklist.
                </p>
              </div>
            </div>
          </div>
        )}

        {terms && (
          <div className="mt-5 border-b border-b-textSecondary sm:border-b-0">
            {/* DESKTOP */}
            <h2 className="sm:hidden gotham text-sm uppercase text-textSecondary text-left">
              Please select the relevant box below*
            </h2>
            <p className="sm:hidden text-textSecondary gotham text-[12px] mt-1 text-left">
              Note you must select one option, in order to proceed with this gym
              membership.
            </p>
            {/* MOBILE */}
            <div className="sm:block hidden pb-[6px] border-b border-b-white border-opacity-40 mt-8">
              <h2 className="text-base text-white font-bold leading-[160%] uppercase">
                pre-exercise checklist*
              </h2>
            </div>
            <p className="sm:block hidden text-textSecondary text-base leading-[21.36px] mt-3 sm:font-normal">
              Please complete the following questions, so we are aware of your
              current health situation:
            </p>
            {/* ------------ */}
            <div className="flex flex-col  ">
              {healthQuestions
                .filter((i) => i.sectionLabel.toLowerCase() == 'health check')
                .filter((i) => i.answerType === 'NUMBER')
                .map((i, index: number) => {
                  const initValue = handleOnOffInitial(i);
                  return (
                    <div
                      key={index}
                      className="relative group flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0"
                    >
                      <div className="text-gray flex items-center gap-[5px] gotham text-left">
                        {isSubmitted &&
                          i.mandatory &&
                          ((i.expectedAnswer !== '' &&
                            i.userValue !== i.expectedAnswer) ||
                            i.userValue === '') && (
                            <div className="tooltip inline-block  text-red-500">
                              <svg
                                className="text-red-500"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12 8V13"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11.9941 16H12.0031"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>{' '}
                              <span className="triangle invisible absolute bottom-[calc(100%+2px)] left-[5px] w-[13px] h-3 rotate-45 border-white border-b border-r z-10 bg-primary"></span>
                              <span className="border border-white tooltiptext bottom-[calc(100%+8px)]">
                                Desired{' '}
                                {i.expectedAnswer === ''
                                  ? 'to answer this question '
                                  : 'answer is ' + i.expectedAnswer + ' '}{' '}
                                or please seek medical advise.
                              </span>
                            </div>
                          )}
                        {i.question}
                      </div>

                      <Switch
                        initial={initValue}
                        onChange={(value: string) => {
                          const data: UIQuestion[] = [...healthQuestions].map(
                            (item, idx) => {
                              return {
                                ...item,
                                userValue:
                                  item.id === i.id
                                    ? value === 'on'
                                      ? 'yes'
                                      : value === 'off'
                                      ? 'no'
                                      : item.userValue
                                    : item.userValue,
                              };
                            }
                          );
                          setHealthQuestions([...data]);
                        }}
                        isYesNo
                      />
                    </div>
                  );
                })}
              <div className="grid grid-cols-2 grid-flow-row gap-4">
                {healthQuestions
                  .filter((i) => i.sectionLabel.toLowerCase() == 'health check')
                  .filter((i) => i.answerType === 'STRING')
                  .map((i, index: number) => {
                    return (
                      <div
                        key={index}
                        className="flex items-start flex-col gap-4 py-3 sm:px-0 relative"
                      >
                        <InputBase
                          holder={i.question}
                          style="w-full"
                          isRequired={true}
                          onchange={(e) => {
                            const data: UIQuestion[] = [...healthQuestions].map(
                              (item, idx) => {
                                return {
                                  ...item,
                                  userValue:
                                    i.id === item.id
                                      ? e.target.value
                                      : item.userValue,
                                };
                              }
                            );
                            setHealthQuestions([...data]);
                          }}
                          value={i.userValue}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            {terms && (
              <>
                <div className="sm:hidden pb-[7px] border-b-2 border-b-white mt-[44px]">
                  <h2 className="heading2 !text-[14px] text-white uppercase text-left font-medium">
                    medical declaration
                  </h2>
                </div>

                <div className="flex flex-col  ">
                  {healthQuestions
                    .filter(
                      (i) =>
                        i.sectionLabel.toLowerCase() == 'medical declaration'
                    )
                    .filter((i) => i.answerType === 'NUMBER')
                    .map((i, index: number) => {
                      const initValue = handleOnOffInitial(i);
                      return (
                        <>
                          {i.answerType === 'NUMBER' && (
                            <div
                              key={index}
                              className="relative group flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0"
                            >
                              <div className="text-gray flex items-center gap-[5px] gotham text-left">
                                {isSubmitted &&
                                  i.mandatory &&
                                  i.userValue !== i.expectedAnswer && (
                                    <div className="tooltip inline-block  text-red-500">
                                      <svg
                                        className="text-red-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                          stroke="currentColor"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M12 8V13"
                                          stroke="currentColor"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M11.9941 16H12.0031"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>{' '}
                                      <span className="triangle invisible absolute bottom-[calc(100%+2px)] left-[5px] w-[13px] h-3 rotate-45 border-white border-b border-r z-10 bg-primary"></span>
                                      <span className="border border-white tooltiptext bottom-[calc(100%+8px)]">
                                        expected answer is {i.expectedAnswer}{' '}
                                      </span>
                                    </div>
                                  )}
                                {i.question}
                              </div>
                              {/* <Switch
                          initial={initValue}
                          onChange={(value: string) => {
                            const data: UIQuestion[] = [...healthQuestions].map(
                              (item, idx) => {
                                return {
                                  ...item,
                                  userValue:
                                    item.id == i.id
                                      ? value == 'on'
                                        ? 'yes'
                                        : 'no'
                                      : item.userValue,
                                };
                              }
                            );
                            setHealthQuestions([...data]);
                          }}
                          isYesNo
                        /> */}
                              <CheckboxActive
                                onChecked={(v) => {
                                  const value = v ? 'on' : 'off';
                                  const data: UIQuestion[] = [
                                    ...healthQuestions,
                                  ].map((item, idx) => {
                                    return {
                                      ...item,
                                      userValue:
                                        item.id == i.id
                                          ? value == 'on'
                                            ? 'yes'
                                            : 'no'
                                          : item.userValue,
                                    };
                                  });
                                  setHealthQuestions([...data]);
                                }}
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                  <div className="grid grid-cols-2 grid-flow-row gap-4">
                    {healthQuestions
                      .filter(
                        (i) =>
                          i.sectionLabel.toLowerCase() == 'medical declaration'
                      )
                      .filter((i) => i.answerType === 'STRING')
                      .map((i, index: number) => {
                        return (
                          <div
                            key={index}
                            className="flex items-start flex-col gap-4 py-3 sm:px-0 relative"
                          >
                            <InputBase
                              holder={i.question}
                              style="w-full"
                              isRequired={true}
                              onchange={(e) => {
                                const data: UIQuestion[] = [
                                  ...healthQuestions,
                                ].map((item, idx) => {
                                  return {
                                    ...item,
                                    userValue:
                                      i.id === item.id
                                        ? e.target.value
                                        : item.userValue,
                                  };
                                });
                                setHealthQuestions([...data]);
                              }}
                              value={i.userValue}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div className="sm:hidden pb-[7px] border-b-2 border-b-white mt-[40px]">
          <h2 className=" heading2 !text-[14px] text-white uppercase text-left font-medium">
            Emergency Contact
          </h2>
        </div>
        <h2 className="sm:block sm:my-7 hidden relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
          Emergency Contact
        </h2>
        <div className="mt-[25px] grid grid-cols-3 sm:grid-cols-1 sm:gap-y-[23px]">
          <InputBase
            nameInput="emergencyContactName"
            holder="Emergency contact name*"
            style="lg:w-full"
            isRequired={true}
            value={updatedInfo.emergencyContactName}
            onchange={handleChange}
            revertColor={width && width <= breakpoints.sm ? true : false}
          />
          <InputBase
            nameInput="emergencyContactRelation"
            holder="Relation to you*"
            style="lg:w-full"
            isRequired={true}
            value={updatedInfo.emergencyContactRelation}
            onchange={handleChange}
            revertColor={width && width <= breakpoints.sm ? true : false}
          />
          <InputNumber
            prefix={prefixEmergencyNumber}
            onChangePrefix={(value) => setPrefixEmergencyNumber(value)}
            nameInput="emergencyContactNumber"
            holder="Emergency phone*"
            style="lg:w-full"
            isRequired={true}
            value={updatedInfo.emergencyContactNumber}
            onchange={handleChange}
            revertColor={width && width <= breakpoints.sm ? true : false}
          />

          {/* <div className="mt-[50px]"><h2 className="text-brand-clime text-[30px] bebas font-normal uppercase border-b border-b-brand-clime pb-2">TERMS AND CONDITIONS</h2></div> */}
        </div>
        {/* DESKTOP T&C*/}
        <div className="sm:hidden flex items-center gap-[16px] mt-6 px-1">
          <CheckboxActive onChecked={(e) => setAccept(e)} />
          <p className="text-gray gotham select-none max-w-[780px] text-xs">
            I agree to the{' '}
            <span
              className="underline cursor-pointer text-xs"
              onClick={() => setTAndC(true)}
            >
              terms & conditions
            </span>{' '}
            and the{' '}
            <span
              className="underline cursor-pointer text-xs"
              onClick={() => setPrivacyPolicy(true)}
            >
              privacy policy
            </span>{' '}
            attached to my membership.*
          </p>
        </div>
        {/* MOBILE T&C */}
        {terms && (
          <div>
            <div className="sm:block hidden pb-[6px] border-b border-b-white border-opacity-40 mt-8">
              <h2 className="text-base text-white font-bold leading-[160%] uppercase">
                Terms and conditions*
              </h2>
            </div>
            <div className="sm:flex hidden items-start gap-x-4 mt-6 px-1">
              <CheckboxActive onChecked={(e) => setAccept(e)} />
              <p className="text-base text-white leading-[133.5%]">
                I confirm I have read and accept the terms and conditions.
              </p>
            </div>
          </div>
        )}

        <div className="flex items-center mt-6 justify-between sm:mt-10">
          <div className="sm:hidden" onClick={() => setStep(step - 1)}>
            <ArrowButton text="back" isRight={false} />
          </div>
          <div className="sm:block hidden" onClick={() => setStep(step - 1)}>
            <ArrowButton text="" isRight={false} />
          </div>
          <div
            onClick={() => {
              if (
                ((accept && window.innerWidth > 768) ||
                  (window.innerWidth <= 768 && accept && confirmRequirement)) &&
                healthCheck()
              ) {
                setStep(step + 1);
              } else {
                toastError('please accept the term and condition checkbox');
              }
            }}
          >
            <ArrowButton text="next" isRight={true} />
          </div>
        </div>
      </div>
    );
  };
  // const [addMembershipToMember] = useMutation(ADD_MEMBERSHIP_TO_MEMBER);
  const [isSameInfo, setIsSameInfo] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>('useCardOnFile');
  const renderHeadingTitleMobile = React.useMemo(() => {
    switch (step) {
      case 1:
        return 'CHANGE MEMBERSHIP';
      case 2:
        return 'CHANGE MEMBERSHIP';
      case 3:
        return 'CONFIRM YOU DETAILS';
      case 4:
        return 'CONFIRM YOUR PAYMENT DETAILS';
      case 5:
      case 6:
        return 'YOUR MEMBERSHIP IS NOW UPDATED';
      default:
        return 'NOT FOUND';
    }
  }, [step]);

  const ConfirmPaymentDetail = () => {
    return (
      <div>
        <p className="sm:hidden uppercase font-bold leading-[90%] text-[30px] text-white h-[46px]">
          Confirm your PAYMENT details
        </p>
        <div className="">
          {paymentMethod === 'creditCard' && (
            <div className="hidden sm:flex items-center gap-x-4 mb-7 mt-9">
              {/* <CheckboxActive onChecked={() => setIsSameInfo(!isSameInfo)} /> */}
              <p className="text-white text-base leading-[21.36px] font-normal">
                Same card as above
              </p>
            </div>
          )}
          <div>
            {paymentMethod === 'bankAccount' && !isSameInfo && (
              <div className="mt-[33px] grid grid-cols-6 gap-y-[13px] sm:gap-y-0 sm:grid-cols-1 sm:mt-5">
                <div className="col-span-2 flex flex-col sm:gap-y-5 sm:mb-5 gap-y-3">
                  <InputBase
                    holder="Account name"
                    style="lg:w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                  <InputBase
                    holder="BSB*"
                    style="lg:w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                </div>
                <div className="col-span-4 flex flex-col sm:gap-y-5 gap-y-3">
                  <InputBase
                    holder="Bank name"
                    style="lg:w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                  <InputBase
                    holder="Account number"
                    style="lg:w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                </div>
              </div>
            )}
            {!isSameInfo && paymentMethod === 'creditCard' && (
              <div className="mt-[33px] grid grid-cols-6 gap-y-[13px] sm:gap-y-0 sm:grid-cols-1 sm:mt-5">
                <div className="col-span-2 flex flex-col sm:gap-y-5 sm:mb-5 gap-y-3">
                  <InputBase
                    holder="Name on the card*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                  <InputBase
                    holder="Card number*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                </div>
                <div className="col-span-4 flex flex-col sm:gap-y-5 gap-y-3">
                  <InputBase
                    onchange={(e) => setDataAccount(e)}
                    nameInput="cardExpiryDate"
                    holder="Expiry date*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                  <InputBase
                    onchange={(e) => setDataAccount(e)}
                    nameInput="cardCvv"
                    holder="CCV/CVV*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                </div>
              </div>
            )}
            {(loadingModal || changeMembershipLoading) && (
              <div className="fixed top-0 left-0 h-screen w-screen">
                <ThreeDot />
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center mt-6 justify-between">
          <div className="block sm:hidden" onClick={() => setStep(step - 1)}>
            <ArrowButton text="back" isRight={false} />
          </div>
          <div className="sm:block hidden" onClick={() => setStep(step - 1)}>
            <ArrowButton text="" isRight={false} />
          </div>
          <div
            onClick={() => {
              if (
                (!isPaymentDelay && !isVoucherDelay) ||
                (isPaymentDelay &&
                  membershipDetail.joiningFee +
                    membershipDetail.joiningFee2 +
                    membershipDetail.joiningFee3) ||
                (isVoucherDelay &&
                  voucherDetail?.activationFee +
                    voucherDetail?.joiningFee +
                    voucherDetail?.passFee)
              ) {
                const currentPrice =
                  currentVoucherDetail?.membershipDetail?.id ===
                  info.memberDetails.membershipId
                    ? currentVoucherDetail?.type === 'PERCENT'
                      ? (
                          currentVoucherDetail?.membershipDetail?.costPrice -
                          currentVoucherDetail?.membershipDetail?.costPrice *
                            (currentVoucherDetail?.cost * 0.01)
                        ).toFixed(2)
                      : currentVoucherDetail?.cost
                    : userSelectedMembership?.costPrice;
                const newPrice = Object.keys(voucherDetail).length
                  ? voucherDetail?.type === 'PERCENT'
                    ? (
                        voucherDetail?.membershipDetail?.costPrice -
                        voucherDetail?.membershipDetail?.costPrice *
                          (voucherDetail?.cost * 0.01)
                      ).toFixed(2)
                    : voucherDetail?.cost
                  : membershipDetail?.costPrice;

                if (currentPrice >= newPrice) {
                  handleChangeMembershipNoCharge();
                } else {
                  handleOpenMakePaymentModal();
                }
              } else {
                handleRenewMembershipDelay();
              }
            }}
          >
            <ArrowButton text="confirm" isRight={true} />
          </div>
        </div>
      </div>
    );
  };
  const congrats = () => {
    return (
      <div
        id="hide-scroll"
        className="sm:grid-cols-1 sm:!h-full md:h-[70vh] h-full w-full md:overflow-y-scroll overflow-y-hidden grid grid-cols-3 gap-6 sm:gap-0 sm:w-full"
      >
        <div className="col-span-2 sm:mt-9">
          <h2 className="sm:!hidden text-primary border-b border-b-primary pb-2 text-[25px] text-left md:flex hidden uppercase heading2">
            success, you’ve just <br /> change membership
          </h2>
          <div className="mt-[47px] flex flex-col  md:items-start items-start w-full justify-between sm:mt-0">
            <div className="flex flex-col justify-start items-start mb-[20px] sm:mb-6">
              <h2
                style={{ fontSize: '40px' }}
                className="font-bold uppercase text-start text-white  md:mb-3 mb-0 heading font-bicyclette sm:text-[40px] sm:text-left sm:!mb-0"
              >
                Congrats, {userInfo?.givenName} {userInfo?.surname}!
              </h2>
              <div
                className="sm:hidden block mt-4"
                onClick={() => window.location.replace('/dashboard')}
              >
                <ArrowButton text="dashboard " isRight={true} />
              </div>
            </div>
            <div className="sm:hidden flex gap-x-12 items-start justify-between">
              <div>
                {/* <h2 className='gotham text-sm text-white text-left'>Your membership number</h2>
                      <p className='gotham text-textSecondary text-left tracking-[1px] text-[12px] mt-1'>123 456 789</p> */}
                <h2 className="gotham text-sm text-white text-left">
                  Username
                </h2>
                <p className="gotham text-textSecondary text-left tracking-[1px] text-[12px] mt-1">
                  {userInfo.email}
                </p>
              </div>
              <div>
                <h2 className="gotham text-sm text-white text-left">
                  Start date
                </h2>
                <p className="gotham text-textSecondary text-left tracking-[1px] text-[12px] mt-1">
                  Today, {moment().format('DD/MM/YYYY')}
                </p>
              </div>
            </div>
            <div className="sm:block hidden">
              <h2 className="text-white text-base leading-[21.36px] mb-1">
                New membership starts
              </h2>
              <p className="text-textSecondary text-base leading-[21.36px]">
                {moment().format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
          <div className="mt-[57px] sm:mt-7">
            <h2 className="uppercase text-left pb-[10px] border-b border-b-primary text-primary heading2 f font-bicyclette sm:text-xl sm:font-bold">
              next steps
            </h2>
          </div>
          <div className="mt-[28px]">
            <div className="flex md:flex-col flex-row md:items-start pb-3 border-b border-b-borderLine items-center justify-between">
              <div className="flex items-center gap-4 sm:gap-x-5 sm:items-start">
                <h1 className="font-bold md:text-white text-primary heading font-bicyclette sm:text-[30px]">
                  01
                </h1>
                <div className="sm:flex sm:flex-col sm:gap-y-3">
                  <p className="uppercase font-bold md:text-primary text-white heading2 font-bicyclette text-lg sm:text-base">
                    Check your email
                  </p>
                  <p className="sm:block hidden text-white">
                    We’ve sent your membership number, and payment receipt to
                    your nominated email address to your email address.
                  </p>
                </div>
              </div>
              <p className="sm:hidden text-white md:text-left text-right md:mt-2 mt-0">
                We’ve sent your membership number, and payment receipt to your
                nominated <br /> email address to your email address.
              </p>
            </div>
            <div className="flex pt-4 pb-3 md:flex-col flex-row md:items-start border-b border-b-borderLine items-center justify-between">
              <div className="flex items-center gap-4 sm:gap-x-5 sm:items-start">
                <h1 className="font-bold md:text-white text-primary heading font-bicyclette sm:text-[30px]">
                  02
                </h1>
                <div className="sm:flex sm:flex-col sm:gap-y-3">
                  <p className="sm:hidden uppercase font-bold md:text-primary text-white heading2 font-bicyclette text-lg sm:text-base">
                    Check the app
                  </p>
                  <p className="sm:block hidden uppercase font-bold md:text-primary text-white heading2 font-bicyclette text-lg sm:text-base">
                    Download the app
                  </p>
                  <p className="sm:block hidden text-white">
                    Download the Plus Fitness app, get it setup on your phone
                  </p>
                  <div className="sm:flex hidden mt-2 justify-between items-center gap-x-1">
                    <img
                      className="h-[34px] w-1/2 object-contain"
                      src={appstore}
                      alt="appstore"
                    />
                    <img
                      className="h-[53px] w-1/2 object-contain"
                      src={googlePlay}
                      alt="ggplay"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:hidden">
                <p className=" text-white md:text-left text-right md:mt-2 mt-0">
                  Download the Plus Fitness app, get it setup on your phone
                  <br />
                </p>
                <div className="mt-2 flex md:justify-start justify-end items-center gap-3">
                  <img
                    className="h-[34px] w-auto"
                    src={appstore}
                    alt="appstore"
                  />
                  <img
                    className="h-[53px] w-auto"
                    src={googlePlay}
                    alt="ggplay"
                  />
                </div>
              </div>
            </div>
            <div className="flex pt-6 pb-3 md:flex-col flex-row md:items-start items-center justify-between">
              <div className="flex items-center gap-4 sm:gap-x-5 sm:items-start">
                <h1 className="font-bold md:text-white text-primary heading font-bicyclette sm:text-[30px]">
                  03
                </h1>
                <div className="sm:flex sm:flex-col sm:gap-y-3">
                  <p className="uppercase text-left font-bold md:text-primary text-white heading2 font-bicyclette text-lg sm:text-base">
                    Come to your club and <br />
                    start training
                  </p>

                  <p className="sm:block hidden text-white">
                    Congratulations on taking the first step of your fitness
                    journey at Plus Fitness. Simply use the mobile member app to
                    access your gym and get your sweat on!
                  </p>
                </div>
              </div>
              <p className="sm:hidden text-white md:text-left text-right md:mt-2 mt-0">
                Congratulations on taking the first step of your fitness journey
                at Plus Fitness. <br /> Simply use the mobile member app to
                access your gym and get your sweat on!
              </p>
            </div>
          </div>
        </div>
        <div className="hidden sm:block mt-10">
          <PrimaryOutlinedButton
            onClick={() => navigate('/dashboard')}
            className="h-[34px] w-full rounded flex items-center justify-between !px-0 mb-9"
          >
            {/* <span className="blank w-[45px]"></span> */}
            <p className="text-center w-full uppercase text-base font-bold">
              need a personal trainer?
            </p>
            <span className="border-l border-l-white px-3 flex h-[40px] items-center justify-center min-w-[45px]">
              <img className="w-auto h-auto" src={arrowRight} alt="icon" />
            </span>
          </PrimaryOutlinedButton>
          <PrimaryOutlinedButton
            onClick={() => window.location.replace('/dashboard')}
            className="h-[52px] w-full uppercase rounded text-base text-center font-bold"
          >
            Take me to dashboard
          </PrimaryOutlinedButton>
        </div>
        <div className="sm:hidden">
          <img className="h-full w-full" src={congratsImg} />
        </div>
      </div>
    );
  };

  return (
    <>
      {TAndC && (
        <ModalBase noBaseHeader onClickOutSide={() => setTAndC(false)}>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div
                onClick={() => setTAndC(false)}
                className="flex items-center absolute top-[5px] right-[5px] justify-center w-8 h-8 bg-dark rounded-full border border-white cursor-pointer"
              >
                <div className="w-4 h-4 block">
                  <img
                    src="/icons/close.png"
                    alt="close"
                    className="object-fit w-full"
                  />
                </div>
              </div>
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {termAndCondition?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={termAndCondition?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}
      {privacyPolicy && (
        <ModalBase noBaseHeader onClickOutSide={() => setPrivacyPolicy(false)}>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div
                onClick={() => setPrivacyPolicy(false)}
                className="flex items-center absolute top-[5px] right-[5px] justify-center w-8 h-8 bg-dark rounded-full border border-white cursor-pointer"
              >
                <div className="w-4 h-4 block">
                  <img
                    src="/icons/close.png"
                    alt="close"
                    className="object-fit w-full"
                  />
                </div>
              </div>
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {privacyPolicyData?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={privacyPolicyData?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}

      {!Object.keys(membership || {}).length && openModalPayment && (
        <ModalMakePayment
          newLocationId={newLocationId}
          setModalMakePayment={setModalPayment}
          amount={
            Object.keys(voucherDetail || {}).length
              ? voucherDetail?.type === 'PERCENT'
                ? voucherPercentPrice +
                  voucherDetail?.joiningFee +
                  voucherDetail?.activationFee +
                  voucherDetail?.passFee
                : voucherDetail?.activationFee +
                  voucherDetail?.joiningFee +
                  voucherDetail?.passFee +
                  voucherDetail?.cost
              : isVoucherDelay
              ? voucherDetail?.joiningFee +
                voucherDetail?.activationFee +
                voucherDetail?.passFee
              : isPaymentDelay
              ? locationFee !== null
                ? locationFee
                : membershipDetail.joiningFee +
                  membershipDetail.joiningFee2 +
                  membershipDetail.joiningFee3
              : membershipDetail.costPrice +
                (locationFee !== null
                  ? locationFee
                  : membershipDetail.joiningFee +
                    membershipDetail.joiningFee2 +
                    membershipDetail.joiningFee3)
          }
          payloadData={{
            addMembershipArguments: {
              membershipStartDate: new Date().toISOString().split('T')[0],
              newLocationId:
                info?.memberDetails?.newLocationId ??
                info?.memberDetails?.homeLocationId,
              newMembershipId: membershipDetail.membershipNew.NewMembershipId,
              voucherCode: voucherCode,
              healthQuestionnaire: JSON.stringify(healthData),
            },
          }}
          changeMembershipData={membershipDetail}
          onCompleted={() => {
            handleUpdateInfo();
            setRefresh(true);
          }}
          setStep={setStep}
          voucherDetail={voucherDetail}
          paymentType="AMS"
          openModalOutside={setModal}
          isPIF={isPIF}
        />
      )}
      {openModalPayment && Object.keys(membership || {}).length && (
        <ModalMakePayment
          changeMembershipData={changeMembershipData}
          newLocationId={newLocationId}
          setModalMakePayment={setModalPayment}
          amount={amountChangeMembership}
          paymentType="changeMembership"
          payloadData={{
            changeMembershipArgumentsInput: {
              newMembershipId:
                changeMembershipData &&
                changeMembershipData.newMemberShipId?.membershipNew
                  ?.NewMembershipId,
              newLocationId: info.memberDetails.newLocationId
                ? info.memberDetails.newLocationId
                : newLocationId,
              voucherCode: voucherCode,
              membershipId: membership?.membershipId,
            },
          }}
          voucherDetail={voucherDetail}
          setStep={setStep}
          onCompleted={() => {
            handleUpdateInfo();
            setRefresh(true);
          }}
          openModalOutside={setModal}
          isPIF={isPIF}
        />
      )}
      {addMembershipPaymentModal && Object.keys(membership || {}).length && (
        <ModalMakePayment
          payloadData={{
            addMembershipArguments: {
              membershipStartDate: new Date().toISOString().split('T')[0],
              newLocationId: newMembershipLocationId,
              newMembershipId: membershipDetail.membershipNew.NewMembershipId,
            },
          }}
          onCompleted={() => {
            handleUpdateInfo();
            setRefresh(true);
          }}
          newLocationId={newLocationId}
          setModalMakePayment={setAddMembershipPaymentModal}
          amount={
            membershipDetail.costPrice +
            membershipDetail.joiningFee +
            membershipDetail.joiningFee2 +
            membershipDetail.joiningFee3
          }
          paymentType="AMS"
          setStep={setStep}
          openModalOutside={setModal}
          isPIF={isPIF}
        />
      )}

      {/* DESKTOP DESIGN */}
      <div className="relative sm:hidden">
        <div
          className="absolute -top-7 pl-4 gotham text-sm tracking-[1.4px] font-medium text-white uppercase select-none"
          onClick={() => {
            step < 7 && setStep(step + 1);
          }}
        >
          Step {step}
        </div>
        <div
          className={`w-full h-[1px] mt-12 mb-6 bg-white grid grid-cols-5 relative`}
        >
          {new Array(step).fill('').map((i, idx) => (
            <div key={idx} className="bg-primary h-[4px] -mt-[2px]"></div>
          ))}
        </div>

        {step == 1 && (
          <>
            <div className="">
              <p className="mt-[10px] font-bicyclette font-bold text-[30px] leading-[90%] uppercase text-white">
                Lets{' '}
                {membership.status !== 'canceled'
                  ? 'change your membership type'
                  : 'renew your membership'}
              </p>
              <h6 className="mt-[36px] text-white gotham text-sm leading-[140%] uppercase font-medium pb-[7px] w-full border-b border-b-white">
                Before you Start
              </h6>
              <div className="mt-[21px] flex flex-col  justify-start">
                <p className=" text-white gotham text-sm leading-[160%] font-normal">
                  Hello legend, we just wanted to let you know{' '}
                  {membership.status !== 'canceled'
                    ? 'changing your membership type'
                    : 'renewing your membership'}{' '}
                  will change the cost of your fortnightly membership, please
                  confirm you are happy to proceed
                </p>
                <div className="flex items-center justify-start mt-6 gap-[16px]">
                  <PrimaryOutlinedButton
                    onClick={() => {
                      setStep(2);
                      setIsStart(true);
                    }}
                    className="uppercase text-white font-medium border-white border rounded h-[37px] w-[99px] gotham !text-[11.2px]"
                  >
                    yes
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    onClick={() => moveTab(0)}
                    className="uppercase text-white font-medium border-white border w-[107px] !px-0 rounded h-[37px] hover:!bg-[#DE0D0D] hover:!border-[#DE0D0D] hover:text-white  gotham !text-[11.2px]"
                  >
                    no thanks
                  </PrimaryOutlinedButton>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Change home club */}
        {step == 2 && (
          <>
            <p className="mt-[10px] font-bicyclette font-bold text-[30px] leading-[90%] uppercase text-white">
              Select a new plan
            </p>
            <MembershipsList
              currentVoucher={currentVoucherDetail}
              onPrev={() => setStep(1)}
              onNext={() => {
                setStep(step + 1);
              }}
              info={info}
              setInfo={(v) => handleSelect(v)}
              setStep={setStep}
              changeMembership={true}
              setMembershipArrDashboard={setMembershipArr}
              setMembershipType={setMembershipType}
              membership={membership}
              isPIF={isPIF}
            />
          </>
        )}
        {/* Change Membership plan */}
        {step == 3 && ConfirmMembershipInfo()}
        {/* Add & Confirm Upgrade */}
        {step == 4 && ConfirmYourDetails()}
        {/* Confirm Detail */}
        {step == 5 && ConfirmPaymentDetail(setStep)}
        {/* Your Payment */}
        {/* {step == 6 && <YourPayment setStep={setStep} />} */}
        {/* Congrats */}
        {step == 6 && congrats()}
      </div>
      {/* MOBILE DESIGN */}
      {addMembershipLoading ? (
        <div className="fixed top-0 left-0 h-screen w-screen">
          <ThreeDot />
        </div>
      ) : null}

      <div className="hidden sm:block px-6 pt-1 pb-3">
        <div className="flex items-start justify-between gap-x-5">
          <p className="font-bicyclette font-bold text-white text-[25px] uppercase">
            {renderHeadingTitleMobile}
          </p>
          <div
            onClick={() => navigate('/dashboard/memberships')}
            className="cursor-pointer h-[14px] mt-[2px]"
          >
            <img
              className="block w-full object-contain"
              src="/icons/icon_back.png"
              alt="icon-back"
            />
          </div>
        </div>
      </div>
      <div className="relative hidden sm:block px-6 pb-20">
        {/* EXCEPT THE CONGRATS STEP */}
        {step <= 4 && (
          <div className="mt-2 mb-5">
            <div className="h-[6px] w-full bg-transparent">
              <div
                style={{
                  width: `${step * 25}%`,
                }}
                className={`h-full bg-primary`}
              ></div>
            </div>

            <div className="flex items-center justify-between mt-4">
              {[1, 2, 3, 4].map((mobileStep) => {
                return (
                  <div
                    key={mobileStep}
                    className={`${
                      step >= mobileStep ? 'bg-primary ' : 'bg-richBlack '
                    } relative w-9 h-9 rounded-full border border-primary flex items-center justify-center font-bicyclette font-bold leading-[18px] text-white text-xl text-center after:content-[' '] ${
                      mobileStep === 4
                        ? ' '
                        : ' after:absolute after:left-[calc(100%+1px)] after:top-1/2 after:h-[2px] after:w-[calc(25vw)] after:bg-textSecondary'
                    }  `}
                  >
                    {mobileStep}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {step === 1 && (
          <div className="">
            <h2 className="relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              BEFORE YOU START
            </h2>
            <p className="text-white text-base text-justify leading-[21.36px] mt-6 mb-8">
              Hi, we just wanted to let you know{' '}
              {membership.length
                ? 'changing your membership type'
                : 'renewing your membership'}{' '}
              will change the cost of your fortnightly membership, please
              confirm you are happy to proceed
            </p>

            <div className="flex items-center justify-center gap-x-5 mt-1">
              <PrimaryOutlinedButton
                onClick={() => setStep(2)}
                className="w-1/2 uppercase text-white border-white border rounded h-[34px] gotham text-base font-bold leading-[160%]"
              >
                yes
              </PrimaryOutlinedButton>
              <PrimaryOutlinedButton
                onClick={() => moveTab(0)}
                className="w-1/2 uppercase text-white border-white border rounded h-[34px] hover:!bg-[#DE0D0D] hover:!border-[#DE0D0D] hover:text-white  gotham text-base font-bold leading-[160%]"
              >
                no thanks
              </PrimaryOutlinedButton>
            </div>
          </div>
        )}

        {/* Change home club */}
        {step === 2 && (
          <div>
            <h2 className="relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              SELECT MEMBERSHIP PLAN
            </h2>
            <MembershipsList
              currentVoucher={currentVoucherDetail}
              onPrev={() => setStep(1)}
              onNext={() => {
                setStep(step + 1);
              }}
              info={info}
              setInfo={(v) => handleSelect(v)}
              setStep={setStep}
              changeMembership={true}
              setMembershipType={setMembershipType}
              isDashboardMobile
            />
          </div>
        )}

        {/* Change Membership plan - combine step 3 and 4 */}
        {step === 3 && (
          <div>
            <h2 className="relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              Membership plan
            </h2>
            {ConfirmMembershipInfo()}

            <h2 className="mt-9 relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              health check
            </h2>
            {ConfirmYourDetails()}
          </div>
        )}

        {step === 4 && (
          <div>
            <h2 className=" sm:block hidden relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              CONFIRM YOUR PAYMENT DETAILS
            </h2>
            <div className="mt-10">{ConfirmPaymentDetail()}</div>
          </div>
        )}

        {step === 6 && <div>{congrats()}</div>}
      </div>
    </>
  );
}

export default ChangeClub;
