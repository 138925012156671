import 'flatpickr/dist/themes/material_green.css';
import React, { useContext, useEffect, useState } from 'react';

import { Auth, Storage } from 'aws-amplify';
import { Link, navigate } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { AuthContext } from '@/api/auth';
import { fullMemberData, updateMemberInfo } from '@/api/mutation';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import InputAnimated from '@/common/inputs/InputAnimated';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import LayoutDashboard from '@/components/LayoutDashboard';
import ModalConfirmOtp from '@/components/LayoutDashboard/Sidebar/modals/ModalConfirmOtp';
import { breakpoints } from '@/constants';
import { uppercaseFirstLetter } from '@/helpers/string.helper';
import useWindowSize from '@/hooks/useWindowSize';
import { useLazyQuery, useMutation } from '@apollo/client';

import Biling from './components/Billing';

import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';
import SaveIcon from '@/assets/images/icon-save.png';

function Account() {
  const profileTabs = [
    'personal information',
    // 'password',
    'billing information',
    'notifications',
  ];

  const [avatar, setAvatar] = useState(null);

  const handleChangeAvatar = async (e: any) => {
    const file = e.target.files[0];
    if (file) setAvatar(file);
  };
  const [tabCurrent, setTab] = useState(0);
  const [isShow, setShow] = useState(false);
  const [selectedState, setSelectedState] = useState(null);

  const [isEditPersonalInfo, setIsEditPersonalInfo] =
    React.useState<boolean>(false);
  const [isEditContactDetails, setIsEditContactDetails] =
    React.useState<boolean>(false);
  const [isEditMobileNumber, setIsEditMobileNumber] =
    React.useState<boolean>(false);
  const [isEditPrimaryEmail, setIsEditPrimaryEmail] =
    React.useState<boolean>(false);

  const [userInfo, setUserInfo] = useState();
  const [updatedInfo, setUpdatedInfo] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    givenName: '',
    homeLocationId: '',
    imageUrl: '',
    joinLocationId: '',
    memberId: '',
    postCode: '',
    state: '',
    suburb: '',
    surname: '',
    title: '',
    workPhone: '',
    secondaryEmail: '',
    middleName: '',
    mobileNumber: '',
    emergencyContactRelation2: '',
    emergencyContactRelation: '',
    emergencyContactNumber2: '',
    emergencyContactNumber: '',
    emergencyContactName2: '',
    emergencyContactName: '',
    emergencyContactEmail2: '',
    emergencyContactEmail: '',
    country: '',
    address: '',
  });

  const initUpdatedInfo = React.useRef<any>(null);
  const initGender = React.useRef<any>(null);
  const initState = React.useRef<any>(null);
  const stateRef = React.useRef();

  const [isShowGender, setShowGender] = useState(false);
  const [selectedGender, setSelectedGender] = useState();
  const handleSelectedGender = (value) => {
    setUpdatedInfo({ ...updatedInfo, gender: value });
    setSelectedGender(value);
  };
  const handleSelectedState = (value) => {
    value != 'NONE' ? setSelectedState(value) : setSelectedState(null);
  };

  const color = {
    'club lime': '#B3E833',
    'hiit republic': '#FF6900',
    'ground up': '#A4ACD7',
  };
  const [qrMember] = useLazyQuery(fullMemberData);

  const fetchData = async () => {
    const userData = JSON.parse(localStorage.getItem('currentUserDetail'));
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        qrMember({
          fetchPolicy: 'no-cache',
          variables: {
            memberId: user.attributes['custom:memberId'],
          },
        })
          .then(async (res) => {
            const userData = res.data.getMember;
            localStorage.setItem('currentUserDetail', JSON.stringify(userData));
            const imgUrl = userData?.imageUrl
              ? await Storage.get(userData.imageUrl)
              : '/icons/default-avatar.svg';
            const userInfoHandle = {
              memberId: userData.memberId,
              // aliasMemberId: userData.aliasMemberId,
              email: userData.email,
              secondaryEmail: userData.secondaryEmail,
              // title: userData.title,
              givenName: userData.givenName,
              surname: userData.surname,
              mobileNumber: userData.mobileNumber,
              workPhone: userData.workPhone,
              homePhone: userData.homePhone,
              emergencyContactName: userData.emergencyContactName,
              emergencyContactEmail: userData.emergencyContactEmail,
              emergencyContactNumber: userData.emergencyContactNumber,
              emergencyContactRelation: userData.emergencyContactRelation,
              emergencyContactName2: userData.emergencyContactName2,
              emergencyContactEmail2: userData.emergencyContactEmail2,
              emergencyContactNumber2: userData.emergencyContactNumber2,
              emergencyContactRelation2: userData.emergencyContactRelation2,
              imageUrl: imgUrl,
              address: userData.address,
              suburb: userData.suburb,
              state: userData.state,
              postCode: userData.postCode,
              country: userData.country,
              // joinDate: userData.joinDate,
              // getLinksSms: userData.getLinksSms,
              // accepted: userData.accepted,
              // brandId: userData.brandId,
              // type: userData.type,
              // role: userData.role,
              // staffKind: userData.staffKind,
              // isBlocked: userData.isBlocked,
              homeLocationId: userData.homeLocationId,
              // firstName: userData?.givenName?.split(" ")[0],
              // lastName: userData?.givenName?.split(" ")[2],
              middleName: userData.middleName,
              dob: new Date(userData.dob.split('-').reverse().join('-')),
              gender: userData.gender,
              contracts: userData.contracts,
              ...userData,
            };
            setSelectedGender(userInfoHandle.gender);
            setSelectedState(userInfoHandle.state);
            setUpdatedInfo(userInfoHandle);

            // INIT
            initUpdatedInfo.current = { ...userInfoHandle };
            initGender.current = userInfoHandle.gender;
            setUserInfo(userInfoHandle);
          })
          .catch((err) => console.log(err));
      })
      .catch(() => navigate('/login'));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  const setRefresh = useContext(AuthContext).setRefreshData;

  const [updateMemberApi] = useMutation(updateMemberInfo);
  const [isLoading, setLoading] = useState(false);

  const handleUpdateInfo = async () => {
    try {
      setLoading(true);
      const payload: any = {};

      for (let i = 0; i < Object.keys(userInfo).length; i++) {
        if (!(Object.values(updatedInfo)[i] === Object.values(userInfo)[i])) {
          payload[Object.keys(userInfo)[i]] = Object.values(updatedInfo)[i];
        }
      }
      if (Object.keys(payload).length || selectedState) {
        if (payload.dob)
          payload.dob = new Date(updatedInfo.dob).toISOString().split('T')[0];
        if (selectedState) {
          payload.state = selectedState;
        }
        payload.memberId = updatedInfo.memberId;
        if (payload.email) {
          payload.email = updatedInfo.email.toUpperCase();
        }
        if (
          !(
            payload.secondaryEmail &&
            /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(payload.secondaryEmail)
          )
        ) {
          toastError('Secondary email is not valid');
          return;
        }
        if (isEditPrimaryEmail || !confirmOtp) {
          delete payload.email;
        }
        if (isEditMobileNumber || !confirmOtp) {
          delete payload.mobileNumber;
        }
        const emergencyContactNumber =
          '+' +
          updatedInfo?.emergencyContactNumber2?.split('+')[
            updatedInfo?.emergencyContactNumber2?.split('+').length - 1
          ];
        if (
          updatedInfo?.emergencyContactNumber2 &&
          emergencyContactNumber != '' &&
          emergencyContactNumber != '+' &&
          !isValidPhoneNumber(emergencyContactNumber)
        ) {
          toastError('contact 2 phone number is not valid');
          return;
        }

        if (
          !updatedInfo.emergencyContactName ||
          !updatedInfo.emergencyContactNumber ||
          !updatedInfo.emergencyContactRelation
        ) {
          toastError('Please provide at least one emergency contact');
          return;
        }
        setLoading(true);
        await updateMemberApi({ variables: { input: payload } });
        // toast.success("updated successfully");
        toastSuccess('Updated successfully');
        setTimeout(() => fetchData(), 1000);
      }
      setIsEditPersonalInfo(false);
      setIsEditContactDetails(false);
      setIsEditMobileNumber(false);
      setIsEditPrimaryEmail(false);
      setRefresh(true);
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    setUpdatedInfo({ ...updatedInfo, [e.target.name]: e.target.value });
  };
  const handleUploadImg = async (e) => {
    // const file = e.target.files[0];
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      file = reader.result;
    };
    // reader.readAsDataURL(file);

    if (file) {
      try {
        const uuid = uuidv4() + '.' + file.name.split('.')[1];
        const payload = { ...updatedInfo };
        const name = `memberImages/${payload.memberId}/profile/${uuid}`;

        await Storage.put(name, file);
        const data = await Storage.get(name);
        await updateMemberApi({
          variables: { input: { imageUrl: name, memberId: payload.memberId } },
        });
        setUpdatedInfo({ ...updatedInfo, imageUrl: data });
        setAvatar(data);
        toastSuccess('Successfully post your file');
        e.target.value = '';
        setRefresh(true);
      } catch (error) {
        console.log('Error uploading file: ', error);
        toastError('Error uploading file');
      }
    }
  };
  const renderHeadingTitleMobile = React.useMemo(() => {
    switch (tabCurrent) {
      case 0:
        return 'personal information';
      // case 1:
      //   return 'reset password';
      case 2:
        return 'billing information';
      case 3:
        return 'notifications';
      default:
        return 'NOT FOUND';
    }
  }, [tabCurrent]);
  const handleClickOutside = (e) => {
    if (!stateRef.current?.contains(e.target)) {
      setShow(false);
    }
  };
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const { width } = useWindowSize();
  const handleConfirmChangePhoneNumber = () => {
    setNewPhoneNumber(updatedInfo.mobileNumber);
    setModalConfirm(true);
  };
  const handleConfirmChangePrimaryEmail = () => {
    setNewPrimaryMail(updatedInfo.email);
    setModalConfirm(true);
  };

  const [modalConfirm, setModalConfirm] = useState(false);
  const [isNewPhoneNumber, setIsNewPhoneNumber] = useState(false);
  const [isNewPrimaryMail, setIsNewPrimaryEmail] = useState(false);
  const [newPrimaryMail, setNewPrimaryMail] = useState('');
  const [confirmOtp, setConfirmOtp] = useState(false);

  useEffect(() => {
    if (!confirmOtp && !modalConfirm) {
      setUpdatedInfo({
        ...updatedInfo,
        mobileNumber: userInfo?.mobileNumber,
        email: userInfo?.email,
      });
    }
  }, [confirmOtp, modalConfirm]);

  return (
    <LayoutDashboard>
      {isLoading && (
        <div className="fixed flex items-center h-[100vh] sm:w-[100vw] w-[calc(100vw-339px)] z-50 bg-[rgba(212,212,212,0.2)] ">
          <div className=" animate-spin h-12 w-12  border-r-8 border-8 border-gray border-r-white rounded-full m-auto "></div>
        </div>
      )}
      {modalConfirm && (
        <ModalConfirmOtp
          onClose={() => {
            setModalConfirm(false);
          }}
          onComplete={async () => {
            await setConfirmOtp(true);
            const user = await Auth.currentAuthenticatedUser();
            isNewPhoneNumber &&
              (await Auth.updateUserAttributes(user, {
                phone_number_verified: true,
              }));
          }}
          isNewPhone={isNewPhoneNumber}
          isNewMail={isNewPrimaryMail}
          newPrimaryEmail={newPrimaryMail}
          newPhoneNumber={newPhoneNumber}
        />
      )}
      <div className="sm:px-0 sm:py-0 px-[61.5px] py-[50px]">
        {width && width >= breakpoints.sm && (
          <>
            <Link to="/dashboard">
              <div className=" flex gap-[8px] items-center ">
                <div className="group w-[41px] h-[48px] p-[7px] pl-0">
                  <div className="border overflow-hidden border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                    <img
                      className="duration-300 transition-all group-hover:-translate-x-1/2 h-[15px] w-[15px]"
                      src="/icons/arrow-left-short.svg"
                    />
                  </div>
                </div>
                <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
                  dashboard
                </span>
              </div>
            </Link>
            <p className="text-white gotham text-[40px] font-bold leading-[50px] mt-[20px] mb-[40px] uppercase font-bicyclette">
              my profile
            </p>
            <div className="flex gap-x-[9px] gap-y-0 w-full">
              {profileTabs.map((i, idx) => (
                <div
                  aria-disabled={true}
                  key={idx}
                  className={`gotham  lg:block flex justify-center items-center text-white tracking-[1.4px] font-sm text-center rounded-t-md text-sm py-[7px] cursor-pointer uppercase ${
                    idx === 0 ? 'w-[31.3%]' : ''
                  } ${idx === 1 ? 'w-[16.3%]' : ''} ${
                    idx === 2 ? 'w-[29%]' : ''
                  } ${idx === 3 ? 'w-[23.4%]' : ''} ${
                    idx === tabCurrent && idx !== 3
                      ? 'bg-primary font-bold'
                      : 'bg-[#414141] font-medium hover:bg-[#a0a0a0]'
                  }`}
                  onClick={() => {
                    if (idx !== 2) setTab(idx);
                  }}
                >
                  {i}
                  {i === 'notifications' && (
                    <p className="text-center lg:m-auto ml-[4px] w-[84px] h-[14px] flex items-center justify-center text-[#848484] text-[8px] border border-[#848484] rounded-[4px]">
                      Coming Soon!
                    </p>
                  )}
                </div>
              ))}
            </div>
            <div className="w-full bg-primary h-[2px]"></div>
          </>
        )}
        <div className="hidden sm:block px-6 pt-1 pb-3">
          <div className="flex items-center justify-between gap-x-2">
            <p className="font-bicyclette font-bold text-white text-[25px] leading-[22.5px] uppercase">
              {renderHeadingTitleMobile}
            </p>
            {tabCurrent !== 0 && (
              <div
                onClick={() => setTab(0)}
                className="cursor-pointer h-[14px]"
              >
                <img
                  className="block w-full object-contain"
                  src="/icons/icon_back.png"
                  alt="icon-back"
                />
              </div>
            )}
          </div>
        </div>
        {tabCurrent == 0 && (
          <>
            <div className="mt-[30px] lg:w-full border sm:border-transparent border-[rgba(255,255,255,0.4)] borer-[1.5px] rounded-md sn:hover:bg-transparent sm:bg-transparent bg-[#13181b] hover:bg-[#1F2528] sm:mt-3 sm:hover:bg-transparent">
              <div className="fle x items-center justify-between sm:hidden border-b border-white px-6 py-[20px]">
                <p className="font-medium font-bicyclette text-sm tracking-[1.4px] text-white uppercase">
                  personal information
                </p>
              </div>

              {/* MOBILE */}
              <div className="sm:block hidden px-6">
                <div className="flex items-start gap-x-4">
                  <div className="w-[55px] h-[60px] rounded-[5px] overflow-hidden">
                    <img
                      className={`block w-full ${
                        updatedInfo?.imageUrl
                          ? 'object-contain'
                          : 'object-cover'
                      }`}
                      src={
                        updatedInfo?.imageUrl
                          ? updatedInfo?.imageUrl
                          : '/icons/default-avatar.svg'
                      }
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-base text-white font-bold uppercase leading-[21.36px]">
                      {updatedInfo?.givenName || ''}
                    </p>
                    <div className="flex gap-x-6">
                      {/* <div className="flex flex-col">
                        <p className="text-textSecondary text-sm leading-[160%]">
                          D.O.B.
                        </p>
                        <p className="text-white text-base leading-[133.5%]">
                          {moment(updatedInfo?.dob).format('MM/YYYY')}
                        </p>
                      </div> */}
                      <div className="flex flex-col">
                        <p className="text-textSecondary text-sm leading-[160%]">
                          Gender
                        </p>
                        <p className="text-white text-base leading-[133.5%]">
                          {uppercaseFirstLetter(selectedGender || '')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <PrimaryOutlinedButton className="uppercase rounded-[5px] text-base font-bold leading-[160%] text-white min-h-[34px] h-[34px] w-[152px] mt-6 flex items-center justify-center">
                  Update
                </PrimaryOutlinedButton>
              </div>

              {/* DESKTOP */}
              <div className="grid sm:hidden grid-cols-8 px-[30px] py-[27px]">
                <div className="col-span-2 ">
                  <div className="w-full h-full max-w-[205px] max-h-[227px] flex items-center justify-center pr-[27px] mx-auto">
                    <div className="group relative w-full h-full">
                      <img
                        className="block object-cover"
                        src={
                          updatedInfo?.imageUrl
                            ? updatedInfo?.imageUrl
                            : '/icons/default-avatar.svg'
                        }
                      />
                      <div className="overlayAvatar hidden group-hover:flex items-center justify-center absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-[#13181B] bg-opacity-80 border border-white rounded-md cursor-pointer">
                        <label
                          htmlFor="uploadNewImage"
                          className="text-xs font-semibold uppercase rounded-md cursor-pointer bg-transparent px-3 py-2 text-white border border-solid border-white transition-all duration-300 hover:bg-primary hover:border-primary"
                        >
                          UPLOAD NEW PHOTO
                        </label>
                        <input
                          id="uploadNewImage"
                          type="file"
                          className="hidden"
                          accept="image/png, image/jpeg, image/png"
                          onChange={(e) => handleUploadImg(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-6 grid grid-rows-3 gap-y-6">
                  <div className=" grid grid-cols-2 gap-5">
                    <InputAnimated
                      onChange={handleChange}
                      label="First name"
                      type="text"
                      // value="string"
                      value={updatedInfo.givenName}
                      name="surname"
                      disabled
                    />
                    {/* <InputAnimated
                                            onChange={handleChange}
                                            label="Middle name"
                                            type="text"
                                            // value="string"
                                            value={updatedInfo.middleName}
                                            name="middleName"
                                        /> */}
                    <InputAnimated
                      onChange={handleChange}
                      label="Last name"
                      type="text"
                      value={updatedInfo.surname}
                      // value="string"
                      name="givenName"
                      disabled
                    />
                  </div>
                  <div className=" grid grid-cols-2 gap-5">
                    {/* <div className="relative ">
                      <div
                        className={`flex items-center mt-[15px] pr-[12px] pb-[6px] bg-transparent border-b border-[rgba(255,255,255,0.4)] cursor-pointer w-full focus:ring-1`}
                      >
                        <div className="bg-transparent h-[20px] outline-none w-full  truncate text-white">
                          <span
                            className={`form__label text-gray gotham absolute  transition-all duration-300
                            ${selectedGender
                                ? 'top-0 left-0 text-[11px]'
                                : 'text-sm top-[12px] left-0'
                              }
                            `}
                          >
                            Gender
                          </span>
                          <span className="text-sm gotham font-medium uppercase tracking-widest">
                            {selectedGender}
                          </span>
                        </div>
                        <div
                          className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 ${isShowGender
                            ? 'border-brand-clime rotate-[135deg]'
                            : 'border-gray'
                            }`}
                        ></div>
                      </div>
                      <div
                        className={`w-full bg-[#13181b] border-gray text-white border-2 absolute z-40 ${isShowGender ? '' : 'hidden'
                          }`}
                      >
                        {['MALE', 'FEMALE']?.map((item: any, index: number) => (
                          <div
                            key={index}
                            className="cursor-pointer uppercase gotham font-medium tracking-widest text-xs px-4 py-2 hover:text-black hover:bg-primary"
                            onClick={() => {
                              setShowGender(false);
                              handleSelectedGender(item);
                              setDropdownSelected(true);
                            }}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[30px] lg:w-full border-[rgba(255,255,255,0.4)] border-[1.5px] rounded-md sm:bg-transparent sm:border-transparent bg-[#13181b] hover:bg-[#1F2528] sm:hover:bg-transparent sm:mt-[50px]">
              <div className="flex items-center justify-between sm:border-transparent border-b border-white px-6 sm:py-0 py-[20px]">
                <p className="font-medium text-sm tracking-[1.4px] text-white uppercase leading-[90%] sm:text-[25px] font-bicyclette">
                  contact details
                </p>
                <div
                  className={`sm:hidden rounded-full cursor-pointer !px-4 ${
                    isEditContactDetails
                      ? 'pointer-events-none opacity-40'
                      : ' '
                  }`}
                  onClick={() => setIsEditContactDetails(true)}
                >
                  <img
                    className="block w-5 h-5"
                    src="/icons/pencil-white.png"
                    alt="edit"
                  />
                </div>
              </div>
              <div className="grid sm:grid-cols-1 grid-cols-8 sm:px-6 px-[30px] sm:pb-0 sm:pt-[15px] py-[27px] sm:gap-[18px] gap-y-[30px] gap-x-[18px] sm:gap-y-2">
                <div className="relative  col-span-4">
                  <div
                    className={`  ${
                      !isEditContactDetails
                        ? 'opacity-1'
                        : !isEditPrimaryEmail
                        ? 'pointer-events-none opacity-40'
                        : ' '
                    }`}
                  >
                    <InputAnimated
                      label="Email address (primary)"
                      type="text"
                      value={updatedInfo?.email}
                      // value="string"

                      onChange={handleChange}
                      name="email"
                      disabled={!isEditContactDetails}
                      noUnderline={!isEditContactDetails}
                    />
                  </div>

                  {isEditContactDetails && (
                    <div>
                      {isEditPrimaryEmail ? (
                        <img
                          className="block w-5 h-5 absolute top-3 right-0 cursor-pointer"
                          onClick={() => {
                            setIsEditPrimaryEmail(false);
                            if (updatedInfo.email !== userInfo.email) {
                              setIsNewPhoneNumber(false);
                              setIsNewPrimaryEmail(true);
                              handleConfirmChangePrimaryEmail();
                            }
                          }}
                          src={SaveIcon}
                          alt="edit"
                        />
                      ) : (
                        <img
                          className="block w-4 h-4 absolute top-3 right-0 cursor-pointer"
                          onClick={() => setIsEditPrimaryEmail(true)}
                          src="/icons/pencil-white.png "
                          alt="edit"
                        />
                      )}
                    </div>
                  )}
                </div>

                <div className="col-span-4">
                  <InputAnimated
                    label="Email address (secondary)"
                    type="text"
                    value={updatedInfo?.secondaryEmail}
                    onChange={handleChange}
                    // value="string"
                    name="secondaryEmail"
                    disabled={!isEditContactDetails}
                    noUnderline={!isEditContactDetails}
                  />
                </div>
                <div className="col-span-4 sm:grid-cols-1 grid grid-cols-2 gap-[18px] sm:gap-2">
                  <div className="relative">
                    <div
                      className={`  ${
                        !isEditContactDetails
                          ? 'opacity-1'
                          : !isEditMobileNumber
                          ? 'pointer-events-none opacity-40'
                          : ' '
                      }`}
                    >
                      <label
                        htmlFor="phoneNumber"
                        className={`relative h-full flex justify-end pb-[7px] pt-[17px] md:h-[51px] flex-col border-textSecondary${
                          isEditContactDetails ? ' border-b ' : ''
                        }`}
                      >
                        <span
                          htmlFor="phoneNumber"
                          className={`absolute text-textSecondary z-10 bg-transparent block font-medium  transition-all duration-300 sm:font-normal ${
                            !(updatedInfo?.mobileNumber === '')
                              ? 'top-0 sm:top-[5px] left-0 text-[11px] text-textSecondary'
                              : 'top-[22px] sm:top-[28px] left-10 text-textSecondary text-[14px] '
                          } `}
                        >
                          Mobile number*
                        </span>
                        <PhoneInput
                          id="phoneNumber1"
                          className="inputPhone"
                          placeholder=""
                          enableSearch
                          disableSearchIcon
                          htmlid="phoneNumber"
                          country="au"
                          value={updatedInfo?.mobileNumber}
                          onChange={(phone, country) => {
                            if (phone.startsWith(country?.dialCode + '0')) {
                              phone =
                                country?.dialCode +
                                phone.slice(country?.dialCode.length + 1);
                              if (phone)
                                setUpdatedInfo({
                                  ...updatedInfo,
                                  mobileNumber: !phone.includes('+')
                                    ? '+' + phone
                                    : phone,
                                });
                              else {
                                setUpdatedInfo({
                                  ...updatedInfo,
                                  mobileNumber: '',
                                });
                              }
                            } else {
                              if (phone)
                                setUpdatedInfo({
                                  ...updatedInfo,
                                  mobileNumber: !phone.includes('+')
                                    ? '+' + phone
                                    : phone,
                                });
                              else {
                                setUpdatedInfo({
                                  ...updatedInfo,
                                  mobileNumber: '',
                                });
                              }
                            }
                          }}
                        />
                      </label>
                    </div>

                    {isEditContactDetails && (
                      <div>
                        {isEditMobileNumber ? (
                          <img
                            className="block w-5 h-5 absolute top-3 right-0 cursor-pointer"
                            onClick={() => {
                              setIsEditMobileNumber(false);
                              if (
                                updatedInfo.mobileNumber !==
                                userInfo.mobileNumber
                              ) {
                                if (
                                  isValidPhoneNumber(updatedInfo.mobileNumber)
                                ) {
                                  setIsNewPrimaryEmail(false);
                                  setIsNewPhoneNumber(true);
                                  handleConfirmChangePhoneNumber();
                                } else {
                                  toastError('Mobile number is not valid');
                                }
                              }
                            }}
                            src={SaveIcon}
                            alt="edit"
                          />
                        ) : (
                          <img
                            className="block w-4 h-4 absolute top-3 right-0 cursor-pointer"
                            onClick={() => setIsEditMobileNumber(true)}
                            src="/icons/pencil-white.png "
                            alt="edit"
                          />
                        )}
                      </div>
                    )}
                  </div>

                  <InputAnimated
                    label="Home number"
                    type="number"
                    value={updatedInfo?.homePhone}
                    onChange={handleChange}
                    // value="string"
                    name="homePhone"
                    disabled={!isEditContactDetails}
                    noUnderline={!isEditContactDetails}
                  />
                </div>
                <div className="col-span-4 sm:grid-cols-1 grid grid-cols-2 gap-[18px] sm:gap-2">
                  <InputAnimated
                    label="Suburb"
                    type="text"
                    value={
                      updatedInfo?.suburb == 'undefined'
                        ? ''
                        : uppercaseFirstLetter(updatedInfo?.suburb)
                    }
                    onChange={handleChange}
                    // value="string"
                    name="suburb"
                    disabled={!isEditContactDetails}
                    noUnderline={!isEditContactDetails}
                  />
                  <div className="grid grid-cols-2 gap-[18px] sm:grid-cols-4 sm:gap-3">
                    <div className="relative ">
                      <div
                        className={`flex items-center mt-[15px] pr-[12px] pb-[7px]  border-b border-[rgba(255,255,255,0.4)] cursor-pointer w-full focus:ring-1 ${
                          !isEditContactDetails ? 'border-transparent' : ' '
                        }`}
                        onClick={() => {
                          if (!isEditContactDetails) return;
                          setShow(!isShow);
                        }}
                      >
                        <div className=" h-[20px] outline-none w-full  truncate text-white">
                          <span
                            className={`form__label text-[#848484] gotham absolute  transition-all duration-300
                            ${
                              selectedState
                                ? 'top-0 left-0 text-[11px]'
                                : 'text-sm top-[15px] left-0'
                            }
                            `}
                          >
                            State
                          </span>
                          <span className="text-sm gotham font-medium uppercase tracking-widest">
                            {selectedState}
                          </span>
                        </div>
                        {isEditContactDetails && (
                          <div
                            className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 ${
                              isShow
                                ? 'border-primary rotate-[135deg]'
                                : 'border-gray'
                            }`}
                          ></div>
                        )}
                      </div>
                      <div
                        className={`w-full bg-[#13181b] border-gray text-white border-2 absolute z-40 ${
                          isShow ? '' : 'hidden'
                        }`}
                        ref={stateRef}
                      >
                        {[
                          'ACT',
                          'QLD',
                          'NSW',
                          'VIC',
                          'WA',
                          'TAS',
                          'SA',
                          'NT',
                        ]?.map((item: any, index: number) => (
                          <div
                            key={index}
                            className="cursor-pointer uppercase gotham font-medium tracking-widest text-xs px-4 py-2 hover:text-black hover:bg-primary"
                            onClick={() => {
                              setShow(false);
                              handleSelectedState(item);
                            }}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                    <InputAnimated
                      label="Postcode"
                      type="number"
                      value={updatedInfo?.postCode}
                      onChange={handleChange}
                      name="postCode"
                      disabled={!isEditContactDetails}
                      noUnderline={!isEditContactDetails}
                    />
                  </div>
                </div>
              </div>
              <p className="border-b font-bicyclette border-white font-medium text-sm tracking-[1.4px] sm:border-b-transparent text-white px-6 sm:py-0 py-[20px] uppercase leading-[90%] sm:text-[25px] font-bicyclette sm:mt-12">
                emergency contact details
              </p>
              <div className="grid sm:grid-cols-1 grid-cols-8 sm:pt-[15px] sm:pb-0 sm:px-6 px-[30px] py-[27px] sm:gap-[18px] gap-y-[30px] gap-x-[18px] sm:gap-y-2">
                <h2 className="sm:block hidden w-full sm:col-span-4 uppercase text-base text-primary font-bold leading-[160%] pb-1 border-b border-primary">
                  Contact 1
                </h2>
                <div className="col-span-4">
                  <InputAnimated
                    label="Contact 1 - name*"
                    type="text"
                    value={updatedInfo?.emergencyContactName}
                    // value="string"
                    onChange={handleChange}
                    name="emergencyContactName"
                    disabled={!isEditContactDetails}
                    noUnderline={!isEditContactDetails}
                  />
                </div>
                <div className="col-span-4 grid sm:grid-cols-1 grid-cols-3 gap-[18px] sm:gap-y-2">
                  <div className="col-span-2 sm:col-span-1">
                    {/* <InputAnimated
                      label="Mobile number*"
                      type="text"
                      // value="string"
                      value={updatedInfo?.emergencyContactNumber}
                      onChange={handleChange}
                      name="emergencyContactNumber"
                      disabled={true}
                      // disabled={!isEditContactDetails}
                      noUnderline={!isEditContactDetails}
                    /> */}
                    <label
                      htmlFor="phoneNumber1"
                      className={`relative h-full flex justify-end py-[7px] md:h-[51px] flex-col border-textSecondary${
                        isEditContactDetails ? ' border-b ' : ''
                      }`}
                    >
                      {/* <p
                      className={`absolute top-[-1px] left-0 after:ml-0.5 after:text-white block text-[12px] gotham font-medium text-white `}
                    >
                      Phone number*
                    </p> */}
                      <span
                        htmlFor="phoneNumber1"
                        className={`absolute text-textSecondary z-10 bg-transparent block font-medium  transition-all duration-300 sm:font-normal ${
                          !(updatedInfo?.emergencyContactNumber === '')
                            ? 'top-0 sm:top-[5px] left-0 text-[11px] text-textSecondary'
                            : 'top-[22px] sm:top-[28px] left-10 text-textSecondary text-[14px] '
                        } `}
                      >
                        Mobile number*
                      </span>
                      <PhoneInput
                        id="phoneNumber1"
                        className="inputPhone"
                        placeholder=""
                        enableSearch
                        disableSearchIcon
                        htmlid="phoneNumber"
                        country="au"
                        value={updatedInfo?.emergencyContactNumber || ''}
                        onChange={(phone, country) => {
                          if (phone.startsWith(country?.dialCode + '0')) {
                            phone =
                              country?.dialCode +
                              phone.slice(country?.dialCode.length + 1);
                            if (phone)
                              setUpdatedInfo({
                                ...updatedInfo,
                                emergencyContactNumber: phone,
                              });
                            else {
                              setUpdatedInfo({
                                ...updatedInfo,
                                emergencyContactNumber: '',
                              });
                            }
                          } else {
                            if (phone)
                              setUpdatedInfo({
                                ...updatedInfo,
                                emergencyContactNumber: phone,
                              });
                            else {
                              setUpdatedInfo({
                                ...updatedInfo,
                                emergencyContactNumber: '',
                              });
                            }
                          }
                        }}
                      />
                    </label>
                  </div>
                  <div>
                    <InputAnimated
                      label="Relationship*"
                      type="text"
                      value={updatedInfo?.emergencyContactRelation}
                      // value="string"
                      onChange={handleChange}
                      name="emergencyContactRelation"
                      disabled={!isEditContactDetails}
                      noUnderline={!isEditContactDetails}
                    />
                  </div>
                </div>
                <h2 className="sm:block hidden w-full sm:col-span-4 uppercase text-base text-primary font-bold leading-[160%] pb-1 border-b border-primary">
                  Contact 2
                </h2>
                <div className="col-span-4 ">
                  <InputAnimated
                    label="Contact 2 - name"
                    value={updatedInfo?.emergencyContactName2}
                    type="text"
                    // value="string"
                    onChange={handleChange}
                    name="emergencyContactName2"
                    disabled={!isEditContactDetails}
                    noUnderline={!isEditContactDetails}
                  />
                </div>
                <div className="col-span-4 sm:grid-cols-1 grid grid-cols-3 gap-[18px] sm:gap-y-2">
                  <div className="col-span-2 sm:col-span-1">
                    {/* <InputAnimated
                      label="Mobile number"
                      type="text"
                      value={updatedInfo?.emergencyContactNumber2}
                      // value="string"
                      onChange={handleChange}
                      name="emergencyContactNumber2"
                      disabled={!isEditContactDetails}
                      noUnderline={!isEditContactDetails}
                    /> */}
                    <label
                      htmlFor="phoneNumber"
                      className={`relative h-full flex justify-end py-[7px] md:h-[51px] flex-col  ${
                        updatedInfo?.emergencyContactNumber2 &&
                        updatedInfo?.emergencyContactNumber2 !== ''
                          ? isEditContactDetails
                            ? 'border-textSecondary'
                            : 'border-none'
                          : 'border-textSecondary'
                      }${isEditContactDetails ? ' border-b ' : ''}
                    ${
                      updatedInfo?.emergencyContactNumber2?.length > 0
                        ? ' border-b focus-within:!border-b-primary placeholder:text-transparent'
                        : ''
                    }`}
                    >
                      {/* <p
                      className={`absolute top-[-1px] left-0 after:ml-0.5 after:text-white block text-[12px] gotham font-medium text-white `}
                    >
                      Phone number*
                    </p> */}
                      <span
                        htmlFor="phoneNumber"
                        className={`absolute text-textSecondary z-10 bg-transparent block font-medium  transition-all duration-300 sm:font-normal ${
                          !(updatedInfo?.emergencyContactNumber2 === '')
                            ? 'top-0 sm:top-[5px] left-0 text-[11px] text-textSecondary'
                            : 'top-[22px] sm:top-[28px] left-10 text-textSecondary text-[14px] '
                        } `}
                      >
                        Mobile number*
                      </span>
                      <PhoneInput
                        id="phoneNumber"
                        className="inputPhone"
                        disabled={!isEditContactDetails}
                        placeholder=""
                        htmlid="phoneNumber"
                        country="au"
                        enableSearch
                        disableSearchIcon
                        value={updatedInfo?.emergencyContactNumber2 || ''}
                        onChange={(phone, country) => {
                          if (phone.startsWith(country?.dialCode + '0')) {
                            phone =
                              country?.dialCode +
                              phone.slice(country?.dialCode.length + 1);
                            if (phone)
                              setUpdatedInfo({
                                ...updatedInfo,
                                emergencyContactNumber2: phone,
                              });
                            else {
                              setUpdatedInfo({
                                ...updatedInfo,
                                emergencyContactNumber2: '',
                              });
                            }
                          } else {
                            if (phone)
                              setUpdatedInfo({
                                ...updatedInfo,
                                emergencyContactNumber2: phone,
                              });
                            else {
                              setUpdatedInfo({
                                ...updatedInfo,
                                emergencyContactNumber2: '',
                              });
                            }
                          }
                        }}
                      />
                    </label>
                  </div>
                  <div>
                    <InputAnimated
                      label="Relationship"
                      type="text"
                      // value="string"
                      value={updatedInfo?.emergencyContactRelation2}
                      onChange={handleChange}
                      name="emergencyContactRelation2"
                      disabled={!isEditContactDetails}
                      noUnderline={!isEditContactDetails}
                    />
                  </div>
                </div>
                {/* MOBILE */}
                <div className="sm:block hidden col-span-4">
                  {isEditContactDetails ? (
                    <div className="flex items-center justify-between gap-x-5 w-full mt-3">
                      <PrimaryOutlinedButton
                        onClick={() => {
                          handleUpdateInfo();
                        }}
                        className="flex w-1/2 uppercase rounded-[5px] text-base font-bold leading-[160%] text-white min-h-[34px] h-[34px] justify-center items-center"
                      >
                        Save
                      </PrimaryOutlinedButton>
                      <PrimaryOutlinedButton
                        onClick={() => setIsEditContactDetails(false)}
                        className="flex w-1/2 uppercase rounded-[5px] text-base font-bold leading-[160%] min-h-[34px] h-[34px] justify-center items-center !text-textError border-textError hover:border-textError hover:bg-textError hover:!text-white"
                      >
                        Cancel
                      </PrimaryOutlinedButton>
                    </div>
                  ) : (
                    <PrimaryOutlinedButton
                      onClick={() => setIsEditContactDetails(true)}
                      className="flex uppercase rounded-[5px] text-base font-bold leading-[160%] text-white min-h-[34px] h-[34px] w-[152px] mt-3 justify-center items-center"
                    >
                      Update
                    </PrimaryOutlinedButton>
                  )}
                </div>
                {/* DESKTOP */}
                <div className="sm:hidden flex justify-start gap-[18px] col-span-3">
                  <div
                    className={`w-[131.48px] hover:bg-primary hover:border-primary hover:text-white  h-[37px] flex items-center justify-center font-bold text-white gotham text-[11.2px] lg:h-[37px]  uppercase rounded border border-white cursor-pointer ${
                      !isEditContactDetails
                        ? ' pointer-events-none opacity-40 '
                        : '  '
                    }`}
                    onClick={() => {
                      handleUpdateInfo();
                      // setIsEditContactDetails(false);
                    }}
                  >
                    update
                  </div>
                  <div
                    className={`w-[143.48px] hover:bg-[#DE0D0D] hover:border-[#DE0D0D] hover:text-white  h-[37px] flex items-center justify-center font-bold text-white gotham text-[11.2px] lg:h-[37px]  uppercase rounded border border-white cursor-pointer ${
                      !isEditContactDetails
                        ? ' pointer-events-none opacity-40 '
                        : '  '
                    }`}
                    onClick={() => {
                      setIsEditContactDetails(false);
                      setIsEditMobileNumber(false);
                      setIsEditPrimaryEmail(false);
                      setUpdatedInfo(initUpdatedInfo.current);
                    }}
                  >
                    cancel changes
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonGroup sm:flex hidden flex-col gap-y-5 mt-4 sm:px-6 sm:pb-20 sm:mt-16">
              {/* <PrimaryOutlinedButton
                onClick={() => setTab(1)}
                className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
              >
                reset Password
              </PrimaryOutlinedButton> */}
              <PrimaryOutlinedButton
                onClick={() => setTab(1)}
                className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
              >
                billing Information
              </PrimaryOutlinedButton>
              <PrimaryOutlinedButton
                // onClick={() => setTab(3)}
                disabled={true}
                className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
              >
                Notifications
              </PrimaryOutlinedButton>
            </div>
          </>
        )}
        {/* {tabCurrent == 1 && <Password />} */}
        {tabCurrent == 1 && (
          <Biling
            userInfo={userInfo}
            refresh={fetchData}
            setLoading={setLoading}
          />
        )}
        {/*{tabCurrent == 3 && <Notification />}*/}

        {/* {tabCurrent == 2 && <Billing />} */}
      </div>
    </LayoutDashboard>
  );
}

export default Account;
